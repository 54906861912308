import filterObject from 'utils/filter-object';
import get from 'utils/get';
import isEmpty from 'utils/is-empty';

import Loggable from './loggable';
import { Filter, RequestState } from './types';

interface ConstructorOptions {
    debug?: boolean;
}

type QueryTermFilterValue = { [field: string]: string | number | boolean };
type QueryTermFilterItem = { term: QueryTermFilterValue };

type QueryRangeFilterValue = { [field: string]: { gte?: string | number; lt?: string | number } };
type QueryRangeFilterItem = { range: QueryRangeFilterValue };

type QueryFilterShape<T> = { bool: { should: T[]; minimum_should_match: 1 } | { filter: T[] } };

type QueryTermFilter = QueryFilterShape<QueryTermFilterItem>;
type QueryRangeFilter = QueryFilterShape<QueryRangeFilterItem>;

type QueryFilter = QueryTermFilter | QueryRangeFilter;

type QueryMatch =
    | {
          multi_match: {
              query: string;
              type?: string;
              fields: string[];
          };
      }
    | { match_all: object };

type ValuesOf<T extends object> = T[keyof T];

/**
 * @class
 * @description Converts current application state to an Elasticsearch request.
 *
 * When implementing an onSearch Handler in Search UI, the handler needs to take the
 * current state of the application and convert it to an API request.
 *
 * For instance, there is a "current" property in the application state that you receive
 * in this handler. The "current" property represents the current page in pagination. This
 * method converts our "current" property to Elasticsearch's "from" parameter.
 *
 * This "current" property is a "page" offset, while Elasticsearch's "from" parameter
 * is a "item" offset. In other words, for a set of 100 results and a page size
 * of 10, if our "current" value is "4", then the equivalent Elasticsearch "from" value
 * would be "40". This method does that conversion.
 *
 * We then do similar things for searchTerm, filters, sort, etc.
 */
export default class ElasticQueryBuilder extends Loggable {
    protected static FIELD_NAME_TO_PATH_MAP = {
        type: 'type',
        bulletinFamily: 'bulletinFamily',
        published: 'published',
        modified: 'modified',
        wildExploited: 'enchantments.exploitation.wildExploited',
        epss: 'epss.epss',
        score: 'cvss3.cvssV3.baseScore',
        aiScore: 'enchantments.score.value'
    } as const;

    protected static FIELD_PATH_TO_NAME_MAP: Record<
        ValuesOf<typeof ElasticQueryBuilder.FIELD_NAME_TO_PATH_MAP>,
        string
    > = {
        ['type']: 'type',
        ['bulletinFamily']: 'bulletinFamily',
        ['published']: 'published',
        ['modified']: 'modified',
        ['enchantments.exploitation.wildExploited']: 'wildExploited',
        ['epss.epss']: 'epss',
        ['cvss3.cvssV3.baseScore']: 'cvss3',
        ['enchantments.score.value']: 'aiScore'
    };

    static stateToString(state: RequestState) {
        const filtered = filterObject(
            state,
            (value, key) => {
                switch (key) {
                    case 'current':
                    case 'resultsPerPage':
                        return false;
                    default:
                        return !isEmpty(value);
                }
            },
            { recursive: true }
        );
        return isEmpty(filtered) ? '' : encodeURIComponent(JSON.stringify(filtered));
    }

    static stringToState(stringified: string): RequestState {
        try {
            return JSON.parse(decodeURIComponent(stringified));
        } catch (_error) {
            return {};
        }
    }

    static slugifyQuery(query: {
        bool: {
            must: [QueryMatch];
            filter?: QueryFilter[];
        };
    }) {
        const output: (string | undefined)[] = [];

        const search = get<string>(query, ['bool', 'must', 0, 'multi_match', 'query']);
        output.push(search);

        const filters = get<QueryFilter[]>(query, ['bool', 'filter'], []);

        const getFilterInfo = ({ bool }: QueryFilter) => {
            if ('should' in bool) {
                if ('term' in bool.should[0])
                    return {
                        path: Object.keys(bool.should[0].term)[0],
                        values: bool.should.map(
                            (item) => Object.values(item.term)[0]
                        ) as QueryTermFilterValue[string][],
                        type: 'term',
                        operator: 'or'
                    } as const;

                if ('range' in bool.should[0])
                    return {
                        path: Object.keys(bool.should[0].range)[0],
                        values: bool.should.map(
                            (item) => Object.values(item.range)[0]
                        ) as QueryRangeFilterValue[string][],
                        type: 'range',
                        operator: 'or'
                    } as const;
            }
            if ('filter' in bool) {
                if ('term' in bool.filter[0])
                    return {
                        path: Object.keys(bool.filter[0].term)[0],
                        values: bool.filter.map(
                            (item) => Object.values(item.term)[0]
                        ) as QueryTermFilterValue[string][],
                        type: 'term',
                        operator: 'and'
                    } as const;
                if ('range' in bool.filter[0])
                    return {
                        path: Object.keys(bool.filter[0].range)[0],
                        values: bool.filter.map(
                            (item) => Object.values(item.range)[0]
                        ) as QueryRangeFilterValue[string][],
                        type: 'range',
                        operator: 'and'
                    } as const;
            }
            return {} as never;
        };

        filters.forEach((filter) => {
            const info = getFilterInfo(filter);
            const { values, type, operator } = info;
            const path = info.path.replace(/\.keyword$/g, '');
            const field = ElasticQueryBuilder.FIELD_PATH_TO_NAME_MAP[path] ?? path;

            if (type === 'term') {
                if (values.length > 1) {
                    output.push(`${field} in (${values.join(', ')})`);
                }
                if (values.length === 1) {
                    output.push(`${field} = ${values[0]}`);
                }
            }
            if (type === 'range') {
                const statements = values
                    .map(({ gte, lt }) => {
                        switch (true) {
                            case !!gte && !!lt:
                                return `between (${gte} and ${lt})`;
                            case !!gte:
                                return `greater than ${gte}`;
                            case !!lt:
                                return `less than ${gte}`;
                            default:
                                return '';
                        }
                    })
                    .filter(Boolean);
                if (statements.length > 1) {
                    output.push(`(${field} ${statements.join(` ${operator} `)})`);
                }
                if (statements.length === 1) {
                    output.push(`${field} ${statements[0]}`);
                }
            }
        });

        return output.filter(Boolean).join(' and ') || 'any';
    }

    protected state: RequestState;

    protected query: unknown;

    constructor(options: ConstructorOptions) {
        super({ ...options, name: 'ElasticQueryBuilder' });
    }

    protected buildSort() {
        const { sortDirection, sortField } = this.state;
        if (sortDirection && sortField) {
            return [{ [sortField]: { order: sortDirection } }];
        } else {
            return undefined;
        }
    }

    protected buildMatch(): QueryMatch {
        const { searchTerm } = this.state;
        return searchTerm
            ? {
                  multi_match: {
                      query: searchTerm,
                      fields: [
                          'title',
                          'description',
                          'affectedSoftware.name',
                          'affectedPackage.packageName',
                          'affectedConfiguration.name',
                          'cvelist',
                          'references',
                          'cwe'
                      ],
                      type: 'phrase'
                  }
              }
            : { match_all: {} };
    }

    protected buildFrom() {
        const { current, resultsPerPage } = this.state;
        return current && resultsPerPage ? (current - 1) * resultsPerPage : undefined;
    }

    protected buildFilter(): QueryFilter[] | undefined {
        const { filters = [] } = this.state;

        const output = filters.reduce<(QueryFilter | undefined)[]>((acc, filter) => {
            const path = ElasticQueryBuilder.FIELD_NAME_TO_PATH_MAP[filter.field] ?? filter.field;
            switch (filter.field) {
                case 'type':
                case 'bulletinFamily':
                    acc.push(this.getTermFilter({ ...filter, field: path }));
                    break;
                case 'wildExploited':
                    acc.push(this.getTermFilter({ ...filter, field: path }));
                    break;
                case 'epss':
                    acc.push(this.getRangeFilter({ ...filter, field: path }));
                    break;
                case 'score':
                    acc.push(this.getRangeFilter({ ...filter, field: path }));
                    break;
                case 'aiScore':
                    acc.push(this.getRangeFilter({ ...filter, field: path }));
                    break;
                case 'published':
                case 'modified':
                    acc.push(this.getRangeFilter({ ...filter, field: path }));
                    break;
                default:
                    break;
            }
            return acc;
        }, []);

        const filtered = output.filter((item): item is QueryFilter => Boolean(item));
        return isEmpty(filtered) ? undefined : filtered;
    }

    protected getSize() {
        return this.state.resultsPerPage;
    }

    protected getTermFilter({ field, type, values }: Filter): QueryTermFilter | undefined {
        switch (type) {
            case 'any':
                return {
                    bool: {
                        should: values.map((value) => ({ term: this.getTermFilterValue(field, value) })),
                        minimum_should_match: 1
                    }
                };
            case 'all':
                return {
                    bool: {
                        filter: values.map((value) => ({ term: this.getTermFilterValue(field, value) }))
                    }
                };
            default:
                return undefined;
        }
    }

    protected getTermFilterValue(field, value): QueryTermFilterValue {
        switch (value) {
            case 'false':
                return { [field]: false };
            case 'true':
                return { [field]: true };
            default:
                return { [`${field}.keyword`]: value };
        }
    }

    protected getRangeFilter({ field, type, values }: Filter): QueryRangeFilter | undefined {
        switch (type) {
            case 'any':
                return {
                    bool: {
                        should: values.map((value) => ({ range: this.getRangeFilterValue(field, value) })),
                        minimum_should_match: 1
                    }
                };
            case 'all':
                return {
                    bool: {
                        filter: values.map((value) => ({ range: this.getRangeFilterValue(field, value) }))
                    }
                };
            default:
                return undefined;
        }
    }

    protected getRangeFilterValue(field, value): QueryRangeFilterValue {
        const { from, to } = value ?? {};
        return {
            [field]: {
                ...(to ? { lt: to } : {}),
                ...(from ? { gte: from } : {})
            }
        };
    }

    protected buildRange(min: number, max: number, step = 1) {
        const output: { from: number; to: number; key: string }[] = [];
        for (let from = min; from < max; from += step) {
            const to = from + step;
            output.push({ from, to, key: `${from} - ${to}` });
        }
        return output;
    }

    protected hashQuery(query) {
        const string = JSON.stringify(query);
        let hash = 0;
        for (let i = 0; i < string.length; i++) {
            const char = string.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash &= hash;
        }
        return new Uint32Array([hash])[0].toString(36);
    }

    public getQuery(state: RequestState) {
        this.state = state;
        this.log('State', state);

        const query = {
            /** https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-highlighting.html */
            highlight: {
                fragment_size: 200,
                number_of_fragments: 1,
                fields: { title: {}, description: {} }
            },
            /** https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-source-filtering.html#search-request-source-filtering */
            _source: [
                'id',
                'cvss',
                'title',
                'description',
                'type',
                'href',
                'modified',
                'score',
                'epss',
                'enchantments'
            ],
            aggs: {
                type: { terms: { field: 'type.keyword', size: 220 } },
                bulletinFamily: { terms: { field: 'bulletinFamily.keyword' } },
                wildExploited: { terms: { field: 'enchantments.exploitation.wildExploited' } },
                epss: { range: { field: 'epss.epss', ranges: this.buildRange(0, 1, 0.1) } },
                score: { range: { field: 'cvss3.cvssV3.baseScore', ranges: this.buildRange(0, 10) } },
                aiScore: { range: { field: 'enchantments.score.value', ranges: this.buildRange(0, 10) } }
            },
            /** https://www.elastic.co/guide/en/elasticsearch/reference/7.x/full-text-queries.html */
            query: {
                bool: {
                    must: [this.buildMatch()],
                    filter: this.buildFilter()
                }
            },
            raw_query: this.state.searchTerm?.trim() ?? '',
            /** https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-sort.html */
            sort: this.buildSort(),
            /** https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-from-size.html */
            size: this.getSize(),
            from: this.buildFrom()
        };

        const queryKey = this.hashQuery(query);

        return { query, queryKey };
    }
}
