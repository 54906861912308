export default class Cacheable {
    static CACHE_TIME = 60_000;

    constructor(cache = new Map()) {
        this.connectCache(cache);
    }

    /** Реализуется в наследуемом классе для первичного присвоения свойст*/
    consume(_cachedEntry) {}

    dehydrate() {
        return [...this.cache];
    }

    hydrate(cache) {
        this.cache = new Map(cache);
        this.cache.forEach(({ value }) => this.consume(value));
    }

    connectCache = (cache) => {
        this.cache = cache;
        return this;
    };

    setItemToCache = (key, value) => {
        const updatedTs = Date.now();
        this.cache.set(key, { value, updatedTs });
        return this;
    };

    hasItemInCache = (key) => {
        if (this.cache.has(key)) {
            const cached = this.cache.get(key);
            return Date.now() - cached.updatedTs < Cacheable.CACHE_TIME;
        } else {
            return false;
        }
    };

    getItemFromCache = (key) => {
        if (this.hasItemInCache(key)) {
            return this.cache.get(key).value;
        } else {
            return undefined;
        }
    };

    clearCache = () => {
        this.cache.clear();
    };
}
