import { action, computed, makeObservable, observable } from 'mobx';

import API from 'app/API';
import Cacheable from 'app/store/Cacheable';
import isOkResponse from 'utils/is-ok-response';

export default class ProductsCveStore extends Cacheable {
    static DEFAULT_PAGE_SIZE = 100;

    @observable list = [];
    @observable totalCount = 0;

    @observable letter = '';
    @observable page = 1;
    @observable pageSize = ProductsCveStore.DEFAULT_PAGE_SIZE;
    @observable year = 0;
    @observable month = 0;
    @observable cvss = [];

    @observable isLoading = false;

    /** @type {AbortController | null} */
    controller = null;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }

    consume(cachedEntry) {
        if (cachedEntry.list) {
            this.list = cachedEntry.list;
        }
        if (cachedEntry.totalCount) {
            this.totalCount = cachedEntry.totalCount;
        }
        if (cachedEntry.params) {
            Object.assign(this, cachedEntry.params);
        }
    }

    @action
    loadItems = () => {
        if (this.isLoading) {
            if (this.controller) {
                this.controller.abort();
                this.controller = null;
                this.isLoading = false;
            } else {
                return;
            }
        }

        const request = API.CVE_CATALOGUE_PRODUCTS({
            page: this.page,
            letter: this.letter,
            pageSize: this.pageSize,
            year: this.year,
            month: this.month,
            score: this.score
        });
        const queryKey = request.url;

        if (this.hasItemInCache(queryKey)) {
            return this.consume(this.getItemFromCache(queryKey));
        }

        this.controller = new AbortController();
        this.isLoading = true;
        return API.fetch(request, null, null, null, null, this.controller.signal)
            .then((response) => {
                if (isOkResponse(response)) {
                    this.list = response.data.products.map((it) => ({
                        vendorName: it.vendor_name,
                        productName: it.product_name,
                        cveCount: it.cve_count
                    }));
                    this.totalCount = response.data.totalCount;
                    this.setItemToCache(queryKey, { list: this.list, totalCount: this.totalCount });
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    };

    @action
    setParams = ({
        page = 0,
        pageSize = ProductsCveStore.DEFAULT_PAGE_SIZE,
        letter = '',
        year = 0,
        month = 0,
        cvss = []
    }) => {
        this.page = page;
        this.letter = letter;
        this.pageSize = pageSize;
        this.year = year;
        this.month = month;
        this.cvss = cvss;
        this.setItemToCache('params', { params: { page, pageSize, letter, year, month, cvss } });
        return this;
    };

    @computed
    get score() {
        return this.cvss[0];
    }
}
