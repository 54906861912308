import { makeObservable, observable } from 'mobx';

export default class License {
    @observable allowForeignEmail = false;
    @observable commercialApiList = [];
    @observable maxAgents = 0;
    @observable maxRequests = 0;
    @observable maxScanResults = 0;
    @observable maxSubscriptions = 0;
    @observable name = '';

    constructor() {
        makeObservable(this);
    }
}
