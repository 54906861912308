import fetch from 'cross-fetch';
import qs from 'qs';

import isBrowser from 'utils/is-browser';

const DEFAULT_HEADERS = {
    'Content-Type': 'application/json'
};

const ALLOWED_ERROR_CODES = [112, 117, 153];

class API {
    static JWT = '';
    static JWTLoading = false;
    static BASE_URL = '';
    static transformParams = (params) => params;

    /**
     * Creates current user JWT for microservices
     * @return {Promise<*>}
     */
    static loadJWT = () => {
        if (API.JWTLoading) {
            return new Promise((resolve) => {
                setTimeout(() => resolve(), 1000);
            });
        }
        API.JWTLoading = true;
        return API.fetch(API.LOAD_JWT, {}).then(({ result, data }) => {
            API.JWTLoading = false;
            if (result === 'OK') {
                API.JWT = data.token;
            }
        });
    };

    /**
     * Fetch with presending JWT token
     * @param request
     * @param {object=} requestBody
     * @returns {Promise.<*>}
     */
    static fetchWithJWT(request, requestBody) {
        if (!API.JWT) {
            return API.loadJWT().then(() => API.JWT && API.fetchWithJWT(request, requestBody));
        }

        let url = request.url;
        /** @type {RequestInit} */
        const params = {
            credentials: 'same-origin',
            method: request.method || 'get',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${API.JWT}`
            }
        };

        if (['post', 'put', 'delete'].indexOf(params.method.toLowerCase()) >= 0) {
            params['body'] = JSON.stringify(requestBody);
        } else if (requestBody) {
            url = API.getUrlWithParams(request.url, requestBody);
        }

        function RequestException(value, response) {
            this.value = value;
            this.message = response.text;
            this.status = response.status;
            this.toString = function () {
                return this.value + this.message;
            };
        }

        let rawResponse;

        return fetch(API.BASE_URL + url, API.transformParams(params))
            .then((response) => {
                rawResponse = response;
                if (rawResponse.status === 204) {
                    return response.text();
                }
                return response.json();
            })
            .then((data) => {
                if (rawResponse.status !== 200 && rawResponse.status !== 201 && rawResponse.status !== 204) {
                    throw new RequestException(data, rawResponse);
                }
                return { data, response: rawResponse };
            });
    }

    /**
     * Fetch with presending CSRF token
     * @param request
     * @param {object=} requestBody
     * @param {FormData=} formData
     * @returns {Promise.<*>}
     */
    static fetchWithToken(request, requestBody, formData) {
        /** @type {RequestInit} */
        const params = {
            credentials: 'same-origin',
            method: request.method || 'get'
        };

        /**
         * Fetch Request
         */
        const callback = function (response) {
            requestBody = requestBody || {};
            requestBody['token'] = response.data.token;

            if (formData) {
                const form = new FormData();
                for (const field of Object.keys(requestBody)) {
                    form.append(field, requestBody[field]);
                }
                form.append('json', JSON.stringify({ token: response.data.token }));
                params['body'] = form;
            } else {
                params['headers'] = DEFAULT_HEADERS;
                ['post', 'put', 'delete'].indexOf(params.method.toLowerCase()) >= 0
                    ? (params['body'] = JSON.stringify(requestBody))
                    : (params['searchParams'] = requestBody);
            }

            return fetch(API.BASE_URL + request.url, API.transformParams(params))
                .then((response) => API.processContent(response))
                .then(API.processResponse)
                .catch(API.processError);
        };

        /**
         * Token Request
         */
        return fetch(
            API.BASE_URL + request.token,
            API.transformParams({
                credentials: 'same-origin',
                headers: DEFAULT_HEADERS
            })
        )
            .then((response) => API.processContent(response))
            .then(API.processResponse)
            .then(callback)
            .catch(API.processError);
    }

    /**
     * Send fetch
     * @param request
     * @param {Object=} requestBody
     * @param {boolean | null} rawResponse do not automatically convert response data to JSON
     * @param rawRequest
     * @param {Object} headers additional headers to send
     * @param {AbortSignal | null} signal additional headers to send
     * @returns {*|Promise.<*>}
     */
    static fetch(request, requestBody, rawResponse = null, rawRequest = null, headers = null, signal = null) {
        let url = request.url;
        /** @type {RequestInit & {method: string, headers: object}} */
        const params = {
            credentials: 'same-origin',
            method: request.method || 'get',
            headers: Object.assign(DEFAULT_HEADERS)
        };

        if (['post', 'put', 'delete'].indexOf(params.method.toLowerCase()) >= 0) {
            params['body'] = rawRequest ? requestBody : JSON.stringify(requestBody);
        } else if (requestBody) {
            url = API.getUrlWithParams(request.url, requestBody);
        }

        if (headers) {
            params.headers = Object.assign(params.headers, headers);
        }

        if (signal) {
            params.signal = signal;
        }

        return fetch(API.BASE_URL + url, API.transformParams(params))
            .then((response) => API.processContent(response, rawResponse))
            .then(API.processResponse)
            .catch(API.processError);
    }

    /**
     * Check response status codes and firing errors
     * @param response
     * @param rawResponse
     */
    static processContent(response, rawResponse) {
        if (response.status === 204) {
            return response;
        }

        if (response.status === 402) {
            if (isBrowser()) {
                document.dispatchEvent(new Event('show-captcha'));
            }
        }

        return rawResponse ? response : response.json();
    }

    /**
     * Check response status codes and firing errors
     * @param response
     */
    static processResponse(response) {
        if (response.type === 'basic') {
            return response;
        }

        if (
            typeof response == 'object' &&
            response.result === 'error' &&
            ALLOWED_ERROR_CODES.indexOf(response.data.errorCode) < 0
        ) {
            API.processError(response.data.error);
        }

        return response;
    }

    static processError(error) {
        if (isBrowser()) {
            document.dispatchEvent(new CustomEvent('show-snackbar', { detail: { message: error.message || error } }));
        }
    }

    /**
     * Builds query string from object
     * @param url
     * @param params
     * @returns {string}
     */
    static getUrlWithParams(url, params) {
        return (
            url +
            '?' +
            Object.keys(params)
                .reduce((a, k) => {
                    a.push(k + '=' + encodeURIComponent(params[k]));
                    return a;
                }, [])
                .join('&')
        );
    }
}

/**
 * Error codes
 */
API.errors = {
    NEED_CAPTCHA: 108
};

API.LOAD_JWT = {
    url: '/api/v3/JwtIssuer/getsessiontoken/',
    method: 'post'
};

/**
 * Requests API
 * @type {{method: string, url: string, token: string}}
 */
API.LOGIN = {
    method: 'post',
    url: '/api/v3/useraction/login/',
    token: '/api/v3/useraction/token/'
};
API.ADD = {
    method: 'post',
    url: '/api/v3/useraction/add/',
    token: '/api/v3/useraction/token/'
};
API.MARKETING_AGREEMENT = {
    method: 'post',
    url: '/api/v3/useraction/setMarketingAgreement',
    token: '/api/v3/useraction/token/'
};
API.INFO = {
    url: '/api/v3/useraction/info/',
    token: '/api/v3/useraction/token/'
};
API.LICENSE_LIST = {
    url: '/api/v3/license/licenseList'
};
API.LICENSE_ADD_MEMBER = {
    url: '/api/v3/license/addlicensemember',
    method: 'post',
    token: '/api/v3/useraction/token/'
};
API.LICENSE_REMOVE_MEMBER = {
    url: '/api/v3/license/removelicensemember',
    method: 'post',
    token: '/api/v3/useraction/token/'
};
API.LICENSE_START_TRIAL = {
    method: 'post',
    url: '/api/v3/license/starttrial/',
    token: '/api/v3/useraction/token/'
};
API.USER_INFO_UPDATE = {
    method: 'get',
    url: '/api/v3/useraction/update/'
};
API.USER_LICENSE_LIST = {
    url: '/api/v3/useraction/licenselist/'
};
API.USER_CREDIT_STATS = {
    url: '/api/v3/credit/statistic/'
};
API.EDIT_GET = {
    url: '/api/v3/useraction/edit/',
    token: '/api/v3/useraction/token/'
};
API.EDIT_POST = {
    method: 'post',
    url: '/api/v3/useraction/edit/',
    token: '/api/v3/useraction/token/'
};
API.USER_TOTP_ENABLE = {
    method: 'post',
    url: '/api/v3/useraction/enableTotp/',
    token: '/api/v3/useraction/token/'
};

API.CHANGE_EMAIL = {
    method: 'post',
    url: '/api/v3/useraction/changeemail/',
    token: '/api/v3/useraction/token/'
};

API.EMAIL_CODE_CONFIRM = {
    method: 'post',
    url: '/api/v3/useraction/emailcodeconfirmation',
    token: '/api/v3/useraction/token/'
};

/**
 * Subscription
 */
API.SUBSCRIPTIONS_TOP = {
    url: '/api/v3/subscriptions/top/'
};
API.EMAIL_SUBSCRIPTIONS_LIST = {
    url: '/api/v3/subscriptions/listEmailSubscriptions/'
};
API.WEBHOOK_SUBSCRIPTIONS_LIST = {
    url: '/api/v3/subscriptions/listWebhookSubscriptions/',
    method: 'get'
};
API.SUBSCRIPTIONS_PREVIEW = {
    url: '/api/v3/subscriptions/preview/'
};
API.SUBSCRIPTIONS_EMAIL_ADD = {
    method: 'post',
    url: '/api/v3/subscriptions/addEmailSubscription/',
    token: '/api/v3/useraction/token/'
};
API.SUBSCRIPTIONS_WEBHOOK_ADD = {
    method: 'post',
    url: '/api/v3/subscriptions/addWebhookSubscription/',
    token: '/api/v3/useraction/token/'
};
API.SUBSCRIPTIONS_EMAIL_EDIT = {
    method: 'post',
    url: '/api/v3/subscriptions/editEmailSubscription/',
    token: '/api/v3/useraction/token/'
};
API.SUBSCRIPTIONS_WEBHOOK_EDIT = {
    method: 'post',
    url: '/api/v3/subscriptions/editWebhookSubscription/',
    token: '/api/v3/useraction/token/'
};
API.SUBSCRIPTIONS_EMAIL_REMOVE = {
    method: 'post',
    url: '/api/v3/subscriptions/removeEmailSubscription/',
    token: '/api/v3/useraction/token/'
};
API.SUBSCRIPTIONS_WEBHOOK_REMOVE = {
    method: 'post',
    url: '/api/v3/subscriptions/removeWebhookSubscription/',
    token: '/api/v3/useraction/token/'
};
API.SUBSCRIPTIONS_UNSUBSCRIBE = {
    method: 'get',
    url: '/api/v3/subscriptions/unsubscribe'
};
API.MARKETING_UNSUBSCRIBE = {
    method: 'post',
    url: '/api/v3/useraction/marketingUnsubscribe'
};

/**
 * SUBSCRIPTIONS
 */
API.SUB_TEMPLATES_PUBLIC_LIST = {
    url: '/api/v3/subtemplates/publicTemplates/'
};

/**
 * Login
 */
API.SEND_ACTIVATION = {
    method: 'post',
    url: '/api/v3/useraction/sendactivation/',
    token: '/api/v3/useraction/token/'
};
API.SEND_RECOVERY = {
    method: 'post',
    url: '/api/v3/useraction/sendrecovery/',
    token: '/api/v3/useraction/token/'
};
API.LOGOUT = {
    method: 'post',
    url: '/api/v3/useraction/logout/',
    token: '/api/v3/useraction/token/'
};
API.STATS = {
    method: 'post',
    url: '/api/v3/search/stats/'
};
API.BULLETIN_STATS = {
    url: '/api/v3/search/vulnersStats/'
};
API.FINGERPRINT = {
    method: 'post',
    token: '/api/v3/fingerprint/token',
    url: '/api/v3/fingerprint/'
};
API.CAPTCHA = {
    url: '/api/v3/captcha/'
};
API.CAPTCHA_ANTIBOT = {
    method: 'post',
    url: '/api/v3/captcha/antibot/'
};
API.CAPTCHA_VERIFY = {
    method: 'post',
    url: '/api/v3/captcha/verify/',
    token: '/api/v3/captcha/token'
};
API.CHECK_PASSWORD = {
    method: 'post',
    url: '/api/v3/useraction/checkpassword',
    token: '/api/v3/useraction/token'
};
API.CHANGE_PASSWORD = {
    method: 'post',
    url: '/api/v3/useraction/changepassword',
    token: '/api/v3/useraction/token'
};
API.CHECK_EMAIL = {
    method: 'post',
    url: '/api/v3/useraction/checkemail'
};
API.SEARCH = {
    method: 'post',
    url: '/api/v3/search/lucene/'
};
API.SEARCH_V2 = {
    method: 'post',
    url: '/api/v3/search/search/'
};
API.SEARCH_SOFTWARE = {
    method: 'post',
    url: '/api/v3/burp/softwareui/'
};
API.SEARCH_ID = {
    method: 'post',
    url: '/api/v3/search/id/'
};
API.SEARCH_HISTORY = {
    method: 'get',
    url: '/api/v3/search/history/?id={id}'
};
API.SEARCH_AUTOCOMPLETE = {
    method: 'get',
    url: '/api/v3/search/autocomplete/'
};
API.SEARCH_SOFTWARE_AUTOCOMPLETE = {
    method: 'get',
    url: '/api/v3/search/product/'
};
API.SPELLER = {
    method: 'get',
    url: '/api/v3/search/speller/'
};
API.VOTES_GET = {
    url: '/api/v3/voting/getvote'
};
API.VOTE = {
    method: 'post',
    url: '/api/v3/voting/votebulletin',
    token: '/api/v3/voting/token'
};
API.AGGREGATION = {
    url: '/api/v3/search/aggregation'
};
API.ROBOT_TYPES = {
    url: '/api/v3/search/robots'
};

API.ARCHIVE_COLLECTION = {
    url: '/api/v3/archive/collection'
};
API.ARCHIVE_NASL = {
    url: '/api/v3/archive/nasl'
};

API.NASL_TYPES = {
    url: '/api/v3/nasl/supported/'
};
API.NASL_LINK = {
    url: '/api/v3/nasl/id/'
};

/**
 * Wallarm
 */
API.WALLARM = {
    url: '/api/v3/wallarm/search/'
};

API.AI_SCORE_TEXT = {
    url: '/api/v3/ai/scoretext/'
};

API.AI_CHOICE = {
    url: '/api/v3/ai/choice/'
};

/**
 * Audit
 */
API.AUDIT = {
    method: 'post',
    url: '/api/v3/audit/audit/'
};
API.AUDIT_SUPPORTED_OS = {
    url: '/api/v3/audit/getSupportedOS'
};
API.AUDIT_SCAN_PREVIEW = {
    url: 'api/v3/scan/preview/'
};
API.AUDIT_SCAN_ID = {
    url: '/api/v3/scan/id/'
};
API.AUDIT_SCAN_REMOVE = {
    method: 'post',
    url: '/api/v3/scan/remove/',
    token: '/api/v3/scan/token'
};
API.AUDIT_SCAN_REMOVE_BY_IP = {
    method: 'post',
    url: '/api/v3/scan/removebyip/',
    token: '/api/v3/scan/token'
};
API.AUDIT_SCAN_REMOVE_BY_AGENT_ID = {
    method: 'post',
    url: '/api/v3/scan/removebyagentid/',
    token: '/api/v3/scan/token'
};

/**
 * Agent Scan Reports
 */
API.AGENT_LIST = {
    url: '/api/v3/agent/list'
};
API.AGENT_DELETE = {
    method: 'post',
    url: '/api/v3/agent/delete',
    token: '/api/v3/agent/token'
};
API.AGENT_DELETE_INACTIVE = {
    method: 'post',
    url: '/api/v3/agent/deleteInactive/?days=7',
    token: '/api/v3/agent/token'
};
API.AGENT_REPORTS_SCAN_LIST = {
    url: '/api/v3/reports/scanlist'
};
API.AGENT_REPORTS_VULNS_REPORT = {
    method: 'post',
    url: '/api/v3/reports/vulnsreport'
};
API.AGENT_REPORTS_VULNS_DETAIL = {
    method: 'get',
    url: '/api/v3/reports/vulndetail'
};
API.AGENT_SCAN_STATISTICS = {
    url: '/api/v3/reports/scanstatistic'
};

/**
 * Notification
 */
API.NOTIFICATION_REGISTER = {
    method: 'post',
    url: '/api/v3/subscriptions/notificationRegister/',
    token: '/api/v3/apiKey/token'
};
API.NOTIFICATION_UNREGISTER = {
    method: 'post',
    url: '/api/v3/subscriptions/notificationUnregister/',
    token: '/api/v3/apiKey/token'
};
API.NOTIFICATION_UPDATE = {
    method: 'post',
    url: 'api/v3/subscriptions/notificationKeepalive/',
    token: '/api/v3/apiKey/token'
};

/**
 * API KEY
 */

API.KEY_LIST = {
    url: '/api/v3/apiKey/listkeys'
};
API.KEY_SCOPE_LIST = {
    url: '/api/v3/apiKey/scopeList'
};
API.KEY_LICENSE_TYPE_LIST = {
    url: '/api/v3/apiKey/licenseTypeList'
};
API.KEY_CREATE = {
    method: 'post',
    url: '/api/v3/apiKey/create',
    token: '/api/v3/apiKey/token',
    fields: ['keyID', 'scope', 'ipaddressList', 'licenseType']
};
API.KEY_DELETE = {
    method: 'post',
    url: '/api/v3/apiKey/delete',
    token: '/api/v3/apiKey/token'
};
API.KEY_MODIFY = {
    method: 'post',
    url: '/api/v3/apiKey/modify',
    token: '/api/v3/apiKey/token',
    fields: ['keyID', 'scope', 'ipaddressList', 'licenseType']
};

API.CHECKOUT_EMAIL = {
    method: 'post',
    url: '/api/v3/license/contact/',
    token: '/api/v3/apiKey/token'
};

/**
 * Images
 */
API.IMAGE_GET = {
    method: 'get',
    url: '/api/v3/images/getImage/'
};
API.IMAGE_GET_B64 = {
    method: 'get',
    url: '/api/v3/images/get/'
};

/**
 * User candidate bulletin
 */
API.USER_CANDIDATE_BULLETIN_ADD = {
    method: 'post',
    url: '/api/v3/useraction/candidate_bulletin_add',
    token: '/api/v3/apiKey/token'
};
API.USER_CANDIDATE_BULLETIN_GET = {
    method: 'get',
    url: '/api/v3/useraction/candidate_bulletin_get'
};
API.USER_CANDIDATE_BULLETIN_LIST = {
    method: 'get',
    url: '/api/v3/useraction/candidate_bulletin_list'
};
(API.USER_CANDIDATE_BULLETIN_UPDATE = {
    method: 'post',
    url: '/api/v3/useraction/candidate_bulletin_update',
    token: '/api/v3/apiKey/token'
}),
    (API.USER_CANDIDATE_BULLETIN_DELETE = {
        method: 'post',
        url: '/api/v3/useraction/candidate_bulletin_delete',
        token: '/api/v3/apiKey/token'
    });

// Catalogue
API.CVE_CATALOGUE_VENDORS = ({ page = 1, letter = '', pageSize = 20 } = {}) => {
    const search = qs.stringify({ page, letter, size: pageSize }, { addQueryPrefix: true, skipNulls: true });
    const request = {
        method: 'get',
        url: '/api/v3/catalog/vendors' + search
    };

    return request;
};

API.CVE_CATALOGUE_VENDOR = ({
    page = 0,
    vendorName = '',
    pageSize = 20,
    score = null,
    year = null,
    month = null
} = {}) => {
    const search = qs.stringify(
        { page, vendor_name: vendorName, size: pageSize, score, year, month },
        { addQueryPrefix: true, skipNulls: true }
    );

    const request = {
        method: 'get',
        url: '/api/v3/catalog/products' + search
    };

    return request;
};

API.CVE_CATALOGUE_VENDOR_CVE = ({
    skip = 0,
    size = 0,
    vendorName = '',
    score = null,
    year = null,
    month = null
} = {}) => {
    const search = qs.stringify(
        { skip, vendor_name: vendorName, size, score, year, month },
        { addQueryPrefix: true, skipNulls: true }
    );

    const request = {
        method: 'get',
        url: '/api/v3/catalog/search' + search
    };

    return request;
};

API.CVE_BY_TYPE_CATALOGUE = ({ skip = 0, size = 0, typeName = '', order = '' } = {}) => {
    const request = {
        method: 'post',
        url: `/api/v3/search/search/`,
        body: {
            query: [`type:${typeName}`, order ? `order:${order}` : ''].filter(Boolean).join(' '),
            size,
            skip,
            fields: [
                'bounty',
                'bulletinFamily',
                'cvss',
                'cvss2',
                'cvss3',
                'description',
                'enchantments',
                'epss',
                'href',
                'id',
                'lastseen',
                'modified',
                'published',
                'sourceData',
                'title',
                'type',
                'vhref',
                'viewCount'
            ]
        }
    };

    return request;
};

// @ts-ignore
API.CVE_CATALOGUE_PRODUCTS = ({ page = 0, letter = '', pageSize = 20, year = 0, month = 0, score = null } = {}) => {
    const search = qs.stringify(
        { page, letter, size: pageSize, year, month, score },
        { addQueryPrefix: true, skipNulls: true }
    );

    const request = {
        method: 'get',
        url: '/api/v3/catalog/products' + search
    };

    return request;
};

API.CVE_CATALOGUE_PRODUCT_CVE = ({
    skip = 0,
    size = 0,
    vendorName = '',
    productName = '',
    year = null,
    month = null,
    score = null
} = {}) => {
    const search = qs.stringify(
        { skip, size, vendor_name: vendorName, product_name: productName, year, month, score },
        { addQueryPrefix: true, skipNulls: true }
    );

    const request = {
        method: 'get',
        url: '/api/v3/catalog/search' + search
    };

    return request;
};

export default API;
