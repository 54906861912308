import { action, makeObservable, observable } from 'mobx';

import API from 'app/API';
import Cacheable from 'app/store/Cacheable';
import isOkResponse from 'utils/is-ok-response';

export default class SubscriptionTemplatesStore extends Cacheable {
    @observable loading = false;
    @observable templates = [];

    promise = null;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }

    bootstrap() {
        return this.loadData();
    }

    consume(cachedEntry) {
        if (cachedEntry.templates) {
            this.templates = cachedEntry.templates;
        }
    }

    @action
    loadData = () => {
        const request = API.SUB_TEMPLATES_PUBLIC_LIST;
        const queryKey = request.url;

        if (this.hasItemInCache(queryKey)) {
            return this.consume(this.getItemFromCache(queryKey));
        }

        if (this.promise) {
            return this.promise;
        }

        this.loading = true;

        this.promise = API.fetch(request)
            .then((response) => {
                if (isOkResponse(response)) {
                    this.templates = response.data.subscriptionTemplates.sort(
                        (a, b) => (b.order || 0) - (a.order || 0)
                    );
                    this.setItemToCache(queryKey, { templates: this.templates });
                }
            })
            .finally(() => {
                this.promise = null;
                this.loading = false;
            });

        return this.promise;
    };
}
