import { amber, deepOrange, green, lightGreen, lime, orange, yellow } from '@mui/material/colors';

import isBrowser from 'utils/is-browser';
import parseDate from 'utils/parse-data';

const lightGreenA200 = lightGreen.A200;
const green300 = green['300'];
const lightGreen300 = lightGreen['300'];
const lime300 = lime['300'];
const limeA200 = lime.A200;
const yellow500 = yellow['500'];
const yellow600 = yellow['600'];
const amber500 = amber['500'];
const orange300 = orange['300'];
const orange400 = orange['400'];
const deepOrange300 = deepOrange['300'];
const deepOrange700 = deepOrange['700'];
const deepOrange800 = deepOrange['800'];
const deepOrangeA400 = deepOrange.A400;
const deepOrangeA700 = deepOrange.A700;

const colorGradient = [
    lightGreenA200,
    green300,
    lightGreen300,
    lime300,
    limeA200,
    yellow500,
    yellow600,
    amber500,
    orange300,
    orange400,
    deepOrange300,
    deepOrange700,
    deepOrange800,
    deepOrangeA400,
    deepOrangeA700
];

class Utils {
    /**
     * @param n {Number}
     * @param string {String}
     * @returns {*|string}
     */
    static trunc(n, string) {
        return string && (string.length > n ? string.substr(0, n - 1) + '...' : string);
    }

    static dayOfWeek(day) {
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day];
    }

    static monthOfYear(month) {
        return [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ][month];
    }

    static getDatesRange(startDate, stopDate) {
        const ONE_DAY = 24 * 3600 * 1000;
        const days = [];
        let currentDate = parseDate(startDate).getTime();
        stopDate = parseDate(stopDate).getTime();
        while (currentDate <= stopDate) {
            days.push(parseDate(currentDate));
            currentDate = currentDate + ONE_DAY;
        }
        return days;
    }

    /**
     * @param stringDateA {String}
     * @param stringDateB {String}
     * @param fieldName {String}
     * @param desc {Boolean}
     * @returns {number}
     */
    static sortByDateString(stringDateA, stringDateB, fieldName, desc) {
        try {
            const data =
                parseDate(fieldName ? stringDateA[fieldName] : stringDateA).getTime() -
                parseDate(fieldName ? stringDateB[fieldName] : stringDateB).getTime();
            return desc ? -data : data;
        } catch (e) {
            return -1;
        }
    }

    /**
     * @param a {String}
     * @param b {String}
     * @returns {number}
     */
    static sortByNumberOrString(a, b) {
        try {
            if (typeof a === 'number' && typeof b === 'number') {
                return a - b;
            }

            a = String(a).toUpperCase();
            b = String(b).toUpperCase();
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        } catch (e) {
            return -1;
        }
    }

    static scrollTo(to = 0, scrollDuration = 300, el) {
        const scrollHeight = el ? el.scrollTop : window.scrollY,
            scrollStep = Math.PI / (scrollDuration / 15),
            cosParameter = scrollHeight / 2,
            scrollToTop = to < scrollHeight;
        let scrollCount = 0,
            scrollMargin,
            position,
            isFinished;
        const scrollInterval = setInterval(function () {
            position = el ? el.scrollTop : window.scrollY;
            isFinished = scrollToTop ? position <= to : position >= to;
            if (!isFinished) {
                scrollCount = scrollCount + 1;
                scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
                el ? el.scroll(0, scrollHeight - scrollMargin) : window.scrollTo(0, scrollHeight - scrollMargin);
            } else {
                clearInterval(scrollInterval);
            }
        }, 15);
    }

    /**
     * Check value is IP
     * @param ip {string}
     * @returns {*|boolean}
     */
    static isIP(ip) {
        return new RegExp(Utils.IP_REGEX).test(ip);
    }

    /**
     * Check value is FQDN
     * @param fqdn {string}
     * @returns {*|boolean}
     */
    static isFQDN(fqdn) {
        return new RegExp(Utils.FQDN_REGEX).test(fqdn);
    }

    /**
     * @returns {boolean}
     */
    static isMobile() {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
            // @ts-ignore
        })(isBrowser() ? navigator.userAgent || navigator.vendor || window.opera : '');
        return check || isBrowser()
            ? (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 840
            : check;
    }

    /**
     * @returns {string}
     */
    static htmlDecode(value) {
        return String(value)
            .replace(/lte/g, '<=')
            .replace(/gte/g, '>=')
            .replace(/lt/g, '<')
            .replace(/gt/g, '>')
            .replace(/eq/g, '=')
            .replace(/le/g, '<=')
            .replace(/ge/g, '>=');
    }

    static decodeHtmlCharCodes(str) {
        return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
            return String.fromCharCode(charCode);
        });
    }

    static sanitizeString(value) {
        return String(value)
            .replace(/\\/g, '')
            .replace(/"/g, '')
            .replace(/'/g, '')
            .replace(/\$/g, '')
            .replace(/\[/g, '')
            .replace(/]/g, '')
            .replace(/\^/g, '');
    }

    static formatString(str, args) {
        return str.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined' ? args[number] : match;
        });
    }

    static getColorByScore(score) {
        return Utils.COLOR_GRADIENT[parseInt(score)];
    }

    static debounce = (func, delay) => {
        let inDebounce;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => func.apply(context, args), delay);
        };
    };

    /**
     * @param value
     * @param valueType
     * @returns {*}
     * @throws {SyntaxError} wrong parsed list/dict value
     */
    static toPythonType(value, valueType) {
        switch (valueType) {
            case 'int':
                value = parseInt(value);
                return isNaN(value) ? 0 : Math.floor(value);
            case 'float':
                value = parseFloat(value);
                return isNaN(value) ? 0 : value;
            case 'bool':
                return value !== 'false';
            case 'list':
                return Array.from(JSON.parse(value));
            case 'dict':
                return JSON.parse(value);
            default:
                return value;
        }
    }

    /**
     * @param value
     * @param valueType
     * @returns {*}
     * @throws {SyntaxError} wrong parsed list/dict value
     */
    static fromPythonType(value, valueType) {
        switch (valueType) {
            case 'int':
                return String(value);
            case 'float':
                return String(value);
            case 'bool':
                return String(value);
            case 'list':
                return JSON.stringify(value);
            case 'dict':
                return JSON.stringify(value);
            default:
                return value;
        }
    }

    static toBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // @ts-ignore
            reader.onload = () => resolve(reader.result?.toString().split(',').pop());
            reader.onerror = (error) => reject(error);
        });
    }

    static fromBase64(b64String) {
        try {
            return atob(b64String);
        } catch (e) {
            console.warn("Can't extract Base64 source code", e);
            return b64String;
        }
    }

    static getImage(filename = '') {
        const imageType = isBrowser() ? (navigator.vendor.match(/apple/i) ? '.png' : '.webp') : '.webp';
        return '/static/img/' + filename.toLowerCase() + imageType;
    }

    static validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!re.test(email);
    }

    static validateSlackWebHook(webHook) {
        const re = /https:\/\/hooks\.slack\.com\/services\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/gm;
        return !re.test(webHook);
    }

    static openUrl(url) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
}

Utils.SECOND = 1000;
Utils.MINUTE = Utils.SECOND * 60;
Utils.HOUR = Utils.MINUTE * 60;
Utils.TRUNC_ITEM_TEXT_LENGTH = 256;
Utils.TRUNC_ITEM_TEXT_LENGTH_SMALL = 64;
Utils.FORMATE_DATE_REGEX = '/d{4}-d{1,2}-d{1,2}/g';
Utils.IP_REGEX =
    '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$';
Utils.FQDN_REGEX = '^(?!:\\/\\/)(?=.{1,255}$)((.{1,63}\\.){1,127}(?![0-9]*$)[a-z0-9-]+\\.?)$';
Utils.COLOR_GRADIENT = colorGradient;
Utils.COLOR_MAIN = '#ff6600';

export default Utils;
