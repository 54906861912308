import { format, FormatOptions, isValid } from 'date-fns';
import { enUS } from 'date-fns/locale/en-US';

import parseDate from 'utils/parse-data';

export default function formatDate(dateLike: Date | string | number, tokens: string, options?: FormatOptions) {
    const parsed = parseDate(dateLike);

    return isValid(parsed) ? format(parsed, tokens, { locale: enUS, ...options }) : '';
}
