import { useCallback, useMemo } from 'react';

import get from 'utils/get';

import ElasticQueryBuilder from './elastic-query-builder';
import ElasticQueryRunner from './elastic-query-runner';
import ElasticStateManager from './elastic-state-builder';

const SIZE = 10;

export default function useElasticAutocomplete() {
    const queryBuilder = useMemo(() => new ElasticQueryBuilder({ debug: false }), []);
    const queryRunner = useMemo(() => new ElasticQueryRunner({ debug: false }), []);
    const stateBuilder = useMemo(() => new ElasticStateManager({ debug: false }), []);

    return useCallback(
        async (searchTerm: string) => {
            const state = { searchTerm, resultsPerPage: SIZE };
            const { query } = queryBuilder.getQuery(state);
            const response = await queryRunner.run(query);
            const { results } = await stateBuilder.buildState({
                state,
                hits: get(response, ['data', 'hits', 'hits'], []),
                aggregations: get(response, ['data', 'aggregations'], []),
                totalResults: get(response, ['data', 'hits', 'total'], 0),
                disjunctiveFacetNames: []
            });

            return results.map((result) => {
                const { title, type, id } = result;
                const value = `${id.raw} - ${title.raw}`;

                return {
                    value,
                    onClick: (event: React.MouseEvent) => {
                        event.preventDefault();
                        event.stopPropagation();
                        window.open('/' + [type.raw, id.raw].filter(Boolean).join('/'), '_blank');
                    }
                };
            });
        },
        [queryBuilder, queryRunner, stateBuilder]
    );
}
