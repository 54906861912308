import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import { SvgIcon } from '@mui/material';

import Chat from 'app/ui-kit/icons/Chat.svg';

const useStyles = makeStyles()(() => ({
    zendesk: {
        position: 'fixed',
        right: 32,
        bottom: 32,
        background: '#282D31',
        color: '#fff',
        borderRadius: 12,
        padding: '16px 16px 12px',
        cursor: 'pointer',
        boxShadow: '0px 10px 25px rgba(230, 131, 97, 0.15)',
        zIndex: 100
    }
}));

const PAGES_EXCLUDED = ['/feed'];

const ZenDesk = () => {
    const { classes } = useStyles();
    const location = useLocation();
    const [hidden, setHidden] = useState(PAGES_EXCLUDED.indexOf(location.pathname) >= 0);

    useEffect(() => {
        setupScript();

        const interval = setInterval(() => {
            try {
                hideWidget();
                clearInterval(interval);
            } catch (e) {}
        }, 500);
    }, []);

    const setupScript = () => {
        window.zESettings = {
            webWidget: {
                color: {
                    theme: '#ff6600',
                    launcher: '#ff6600', // This will also update the badge
                    launcherText: '#fff',
                    button: '#ff6600',
                    buttonText: '#fff',
                    header: '#ff6600',
                    headerText: '#fff',
                    articleLinks: '#ff6600'
                },
                launcher: {
                    badge: {
                        chatLabel: {
                            '*': '-'
                        }
                    }
                }
            }
        };

        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=d020931d-c39d-4d6a-9657-d46fb825036d';
        script.async = true;
        document.body.appendChild(script);
    };

    const hideWidget = () => {
        zE('webWidget', 'hide');
        zE('webWidget:on', 'close', function () {
            zE('webWidget', 'hide');
            setHidden(false);
        });
    };

    const handleClick = () => {
        zE('webWidget', 'show');
        zE('webWidget', 'open');
        setHidden(true);
    };

    return hidden ? null : (
        <div className={classes.zendesk} onClick={handleClick}>
            <SvgIcon style={{ width: 32, height: 32 }}>
                <Chat />
            </SvgIcon>
        </div>
    );
};

export default ZenDesk;
