import { RequestState, ResponseState, SearchDriver as BaseSearchDriver, SearchState } from '@elastic/search-ui';

import { ElasticApiConnector } from 'main/modules/elastic/elastic-api-connector';
import ElasticQueryBuilder from 'main/modules/elastic/elastic-query-builder';
import isBrowser from 'utils/is-browser';

interface ConstructorOptions {
    debug?: boolean;
    enabled?: () => boolean;
    initialState?: Partial<RequestState>;
    onSearch: (state: RequestState) => Promise<ResponseState>;
}

export default class ElasticSearchDriver extends BaseSearchDriver {
    constructor(options: ConstructorOptions) {
        const { debug, initialState, onSearch, enabled = () => true } = options;
        const apiConnector = new ElasticApiConnector({ onSearchImplementation: onSearch });

        super({
            debug,
            apiConnector,
            initialState,
            hasA11yNotifications: false,
            trackUrlState: isBrowser(),
            alwaysSearchOnInitialLoad: false,
            urlPushDebounceLength: 0,
            routingOptions: {
                readUrl: () =>
                    new URLSearchParams(window.location.search.replaceAll('+', encodeURIComponent('+')))
                        .get('state')
                        ?.trim() || '',
                writeUrl: (state, { replaceUrl }) => {
                    if (state) {
                        if (replaceUrl) {
                            window.history.replaceState({}, '', `/search?state=${state}`);
                        } else {
                            window.history.pushState({}, '', `/search?state=${state}`);
                        }
                    }
                },
                stateToUrl: (state) => ElasticQueryBuilder.stateToString(state),
                urlToState: (url) => ElasticQueryBuilder.stringToState(url)
            }
        });

        const _originalUpdateSearchResults = this._updateSearchResults.bind(this);

        this._updateSearchResults = (...args) => {
            if (enabled()) {
                return _originalUpdateSearchResults(...args);
            }
        };
    }

    protected hasSearchTermChange(payload: Partial<SearchState>) {
        const oldValue = (this.state.searchTerm ?? '').trim();
        const newValue = (payload.searchTerm ?? this.state.searchTerm ?? '').trim();
        return oldValue !== newValue;
    }

    public setState(payload: Partial<SearchState>) {
        const refined = { ...payload };
        if (this.hasSearchTermChange(payload)) {
            refined.filters = [];
        }
        // @ts-expect-error Making driver state public, God bless JS
        return this._setState(refined);
    }

    public get requestState(): RequestState {
        const { current, filters, resultsPerPage, searchTerm, sortDirection, sortField, sortList } = this.state;
        return { current, filters, resultsPerPage, searchTerm, sortDirection, sortField, sortList };
    }

    /**
     * Shortened and NOT debounced implementation of internal _makeSearchRequest method to let fetching in imperative mode
     */
    public async refetch() {
        this.setState({ isLoading: true });
        const resultState = await this.events.search(this.requestState, {});
        this.setState({ isLoading: false, ...resultState, wasSearched: true });
    }
}
