import { action, computed, makeObservable, observable, reaction } from 'mobx';

import API from 'app/API';
import Cacheable from 'app/store/Cacheable';
import isOkResponse from 'utils/is-ok-response';

export default class StatsStore extends Cacheable {
    @observable loading = false;
    @observable total = {};
    @observable types = {};

    @observable statsLoading = false;
    @observable stats = {
        bulletins: 0,
        exploits: 0,
        avgCvssScore: 0,
        avgBulletinsPerDay: 0,
        avgCvePerDay: 0,
        avgExploitPublicationDate: 0,
        avgWildExploitPublished: 0,
        collections: 0
    };

    constructor(searchStore) {
        super();
        makeObservable(this);
        reaction(
            () => searchStore.settingsOpen,
            () => {
                !this.loaded && this.loadStats();
                !this.statsLoaded && this.loadBulletinStats();
            }
        );
    }

    consume(cachedEntry) {
        if (cachedEntry.total) {
            this.total = cachedEntry.total;
        }
        if (cachedEntry.types) {
            this.types = cachedEntry.types;
        }
        if (cachedEntry.stats) {
            this.stats = cachedEntry.stats;
        }
    }

    @action
    loadStats = () => {
        if (this.loading) {
            return;
        }

        const request = API.STATS;
        const queryKey = request.url;

        if (this.hasItemInCache(queryKey)) {
            return this.consume(this.getItemFromCache(queryKey));
        }

        this.loading = true;

        return API.fetch(request, { type: 'searchHelp' })
            .then((response) => {
                if (process.env.NODE_ENV !== 'production' && !Object.keys(response.data.type_results).length) {
                    response = require('../../tests/mocks/stats.json');
                }

                if (isOkResponse(response)) {
                    this.types = response.data.type_results;
                    this.total = response.data.total_results;
                    this.setItemToCache(queryKey, { total: this.total, types: this.types });
                }
            })
            .catch(() => {
                if (process.env.NODE_ENV !== 'production') {
                    const response = require('../../tests/mocks/stats.json');
                    this.types = response.data.type_results;
                    this.total = response.data.total_results;
                    this.setItemToCache(queryKey, { total: this.total, types: this.types });
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    loadBulletinStats = () => {
        if (this.statsLoading) {
            return;
        }

        const request = API.BULLETIN_STATS;
        const queryKey = request.url;

        if (this.hasItemInCache(queryKey)) {
            return this.consume(this.getItemFromCache(queryKey));
        }

        this.statsLoading = true;

        return API.fetch(request)
            .then((response) => {
                if (process.env.NODE_ENV !== 'production' && !Object.keys(response.data.total_results).length) {
                    response = require('../../tests/mocks/bulletinStats.json');
                }
                if (isOkResponse(response)) {
                    this.stats = response.data.total_results;
                    this.setItemToCache(queryKey, { stats: this.stats });
                }
            })
            .catch(() => {
                if (process.env.NODE_ENV !== 'production') {
                    const response = require('../../tests/mocks/bulletinStats.json');
                    this.stats = response.data.total_results;
                    this.setItemToCache(queryKey, { stats: this.stats });
                }
            })
            .finally(() => {
                this.statsLoading = false;
            });
    };

    @computed get statsLoaded() {
        return !!this.stats.bulletins;
    }

    @computed get loaded() {
        return !!Object.keys(this.types).length;
    }
}
