import { action, makeObservable, observable } from 'mobx';

import API from 'app/API';
import Cacheable from 'app/store/Cacheable';
import isomorphicLocalStorage from 'main/modules/isomorphic-local-storage';
import isBrowser from 'utils/is-browser';
import isOkResponse from 'utils/is-ok-response';

const LS_KEY = 'VULNERS_ROBOT_TYPES';

export default class RobotTypesStore extends Cacheable {
    loading = false;
    fetched = false;
    @observable types = {};

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }

    bootstrap() {
        try {
            const types = isomorphicLocalStorage.getItem(LS_KEY);
            if (!types) {
                if (!Object.keys(this.types) && !this.loading) {
                    return this.load();
                }
            } else {
                this.types = JSON.parse(types);
            }
        } catch (error) {
            return this.load();
        }
    }

    consume(cachedEntry) {
        if (cachedEntry.types) {
            this.types = cachedEntry.types;
            this.fetched = true;
        }
    }

    @action
    load = () => {
        const request = API.ROBOT_TYPES;
        const queryKey = request.url;

        if (this.hasItemInCache(queryKey)) {
            return this.consume(this.getItemFromCache(queryKey));
        }

        this.loading = true;
        return API.fetch(API.ROBOT_TYPES)
            .then((response) => {
                this.types = response.data || {};
                if (isOkResponse(response)) {
                    this.setItemToCache(queryKey, { types: this.types });
                    isomorphicLocalStorage.setItem(LS_KEY, JSON.stringify(response.data));
                }
            })
            .catch(() => {
                if (process.env.NODE_ENV !== 'production') {
                    const response = require('../../tests/mocks/robotTypes.json');
                    this.types = response.data;
                    this.setItemToCache(queryKey, { types: this.types });
                }
            })
            .finally(() => {
                this.loading = false;
                this.fetched = true;
            });
    };

    get = (type) => {
        if (isBrowser()) {
            switch (true) {
                case type in this.types:
                    return this.types[type] || type;
                case this.fetched:
                case this.loading:
                    return type;
                default:
                    this.load();
                    return type;
            }
        } else {
            return this.types[type] || type;
        }
    };
}
