import React, { PropsWithChildren } from 'react';

import ConfigsContext from './context';

interface Props {
    value: React.ContextType<typeof ConfigsContext>;
}

const ConfigsProvider: React.FC<PropsWithChildren<Props>> = ({ value, children }) => {
    return <ConfigsContext.Provider value={value}>{children}</ConfigsContext.Provider>;
};

export default ConfigsProvider;
