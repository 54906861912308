import { useLayoutEffect } from 'react';

interface Options {
    title?: string;
    description?: string;
}

export default function usePageMeta({ title, description }: Options) {
    useLayoutEffect(() => {
        if (title) {
            document.title = [title.replace(/\|\s?vulners(.com)?$/i, '').trim(), 'Vulners.com'].join(' | ');
        }
        if (description) {
            document.querySelector('meta[name=description]')?.setAttribute('content', description);
        }
    }, [description, title]);
}
