const localStorageMock = {
    getItem: () => null,
    setItem: () => undefined
};

export default class LocalCache {
    static storage = typeof window === 'undefined' ? localStorageMock : window.localStorage;

    static get(key) {
        return LocalCache.storage.getItem(key);
    }

    static set(key, value) {
        LocalCache.storage.setItem(key, value);
    }

    static getObject(key) {
        const stringObject = LocalCache.get(key);
        try {
            return JSON.parse(stringObject);
        } catch (e) {
            return null;
        }
    }

    static setObject(key, object) {
        LocalCache.set(key, JSON.stringify(object));
    }
}
