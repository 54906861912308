function get<T>(source: object, path: string | (string | number)[]): T;
function get<T>(source: object, path: string | (string | number)[], defaultValue: T): T;
function get<T>(source: object, path: string | (string | number)[], defaultValue?: T) {
    const normalizedPath = Array.isArray(path) ? path : typeof path === 'string' ? path.split('.') : path;
    let output = source;

    for (let i = 0; i < normalizedPath.length && output !== undefined && output !== null; i++) {
        const key = normalizedPath[i];
        output = output[key];
    }

    return output === undefined ? defaultValue : output;
}

export default get;
