import { addDays, subDays, subMonths, subWeeks, subYears } from 'date-fns';

import { RequestState } from '@elastic/search-ui';

import ElasticQueryBuilder from 'main/modules/elastic/elastic-query-builder';
import ElasticQueryRunner from 'main/modules/elastic/elastic-query-runner';
import Loggable from 'main/modules/elastic/loggable';
import filterObject from 'utils/filter-object';
import formatDate from 'utils/format-date';
import get from 'utils/get';
import isEmpty from 'utils/is-empty';
import keyBy from 'utils/key-by';

import type { ResponseState } from './types';

interface ConstructorOptions {
    debug?: boolean;
}

interface BuildOptions {
    state: RequestState;
    hits: unknown[];
    aggregations: unknown[];
    totalResults: number;
    disjunctiveFacetNames: string[];
}

export default class ElasticStateBuilder extends Loggable {
    protected readonly queryBuilder: ElasticQueryBuilder;
    protected readonly queryRunner: ElasticQueryRunner;

    constructor(options: ConstructorOptions) {
        super({ ...options, name: 'ElasticStateBuilder' });
        this.queryBuilder = new ElasticQueryBuilder(options);
        this.queryRunner = new ElasticQueryRunner(options);
    }

    protected buildResults(data) {
        return data.map((record) => {
            return Object.entries(record._source)
                .map(([key, raw]) => {
                    const snippet = get(record, ['highlight', key, 0]);
                    return [key, snippet ? { raw, snippet } : { raw }] as const;
                })
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
        });
    }

    protected buildTotalPages(resultsPerPage, totalResults) {
        switch (true) {
            case !resultsPerPage:
                return 0;
            case !totalResults:
                return 1;
            default:
                return Math.ceil(totalResults / resultsPerPage);
        }
    }

    protected buildStateFacets(...sources) {
        const iteratee = (item) => item.key_as_string || item.key;

        const aggregations = sources.reduce((output, item) => {
            Object.keys(item).forEach((field) => {
                output[field] ||= {};
                output[field].buckets ||= [];

                output[field].buckets = Object.values({
                    ...keyBy(output[field].buckets, iteratee),
                    ...keyBy(item[field].buckets, iteratee)
                });
            });
            return output;
        }, {});

        const facets = filterObject({
            type: this.getValueFacet(aggregations, 'type'),
            wildExploited: this.getValueFacet(aggregations, 'wildExploited'),
            bulletinFamily: this.getValueFacet(aggregations, 'bulletinFamily'),
            epss: this.getRangeFacet(aggregations, 'epss'),
            score: this.getRangeFacet(aggregations, 'score'),
            aiScore: this.getRangeFacet(aggregations, 'aiScore'),
            published: this.buildDateIntervalFacet('published'),
            modified: this.buildDateIntervalFacet('modified')
        });

        return isEmpty(facets) ? undefined : facets;
    }

    protected buildDateIntervalFacet(field: string) {
        /** Using tomorrow because upper bound of range filter in query-builder will be "lt" instead of "lte" */
        const tomorrow = addDays(new Date(), 1);
        const toValue = (date: Date) => formatDate(date, 'yyyy-MM-dd');

        return [
            {
                field,
                type: 'range',
                data: [
                    {
                        value: {
                            name: 'Today',
                            from: toValue(subDays(tomorrow, 1)),
                            to: toValue(tomorrow)
                        },
                        count: Infinity
                    },
                    {
                        value: {
                            name: 'Last week',
                            from: toValue(subWeeks(tomorrow, 1)),
                            to: toValue(tomorrow)
                        },
                        count: Infinity
                    },
                    {
                        value: {
                            name: 'Last month',
                            from: toValue(subMonths(tomorrow, 1)),
                            to: toValue(tomorrow)
                        },
                        count: Infinity
                    },
                    {
                        value: {
                            name: 'Last year',
                            from: toValue(subYears(tomorrow, 1)),
                            to: toValue(tomorrow)
                        },
                        count: Infinity
                    },
                    {
                        value: {
                            name: 'Any'
                        },
                        count: Infinity
                    }
                ]
            }
        ];
    }

    protected getValueFacet(aggregations, field) {
        const data = get(aggregations, [field, 'buckets'], []).map(({ key, key_as_string, doc_count }) => ({
            value: key_as_string || key,
            count: doc_count
        }));

        return isEmpty(data) ? undefined : [{ field, type: 'value', data, sort: { value: 'asc' } }];
    }

    protected getRangeFacet(aggregations, field) {
        const data = get(aggregations, [field, 'buckets'], [])
            .filter(({ doc_count }) => doc_count > 0)
            .map(({ key, to, from, doc_count }) => {
                return {
                    value: {
                        to,
                        from,
                        name:
                            Number.isInteger(to) && Number.isInteger(from)
                                ? key
                                : Number(from).toFixed(1) + ' - ' + Number(to).toFixed(1)
                    },
                    count: doc_count
                };
            })
            .reverse();
        return isEmpty(data) ? undefined : [{ field, type: 'range', data }];
    }

    protected async getDisjunctiveFacetCounts(options: { state: RequestState; disjunctiveFacetNames: string[] }) {
        const { state, disjunctiveFacetNames } = options;
        const promises = disjunctiveFacetNames.map((facetName) => {
            const refinedState = {
                ...state,
                filters: state.filters?.filter(({ field }) => field !== facetName)
            };

            const { query } = this.queryBuilder.getQuery(refinedState);
            const refinedQuery = { ...query, size: 0, aggs: { [facetName]: query.aggs[facetName] } };
            return this.queryRunner.run(refinedQuery);
        });

        const responses = await Promise.all(promises);
        return responses.reduce((acc, { data: { aggregations } }) => ({ ...acc, ...aggregations }), {});
    }

    public async buildState({ state, hits, aggregations, totalResults, disjunctiveFacetNames }: BuildOptions) {
        const disjunctiveFacetCounts = await this.getDisjunctiveFacetCounts({ state, disjunctiveFacetNames });
        return ({
            results: this.buildResults(hits),
            totalPages: this.buildTotalPages(state.resultsPerPage, totalResults),
            totalResults,
            facets: this.buildStateFacets(aggregations, disjunctiveFacetCounts)
        } as unknown) as ResponseState;
    }
}
