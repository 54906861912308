import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';

import { Check, Close } from '@mui/icons-material';
import { Alert, Snackbar } from '@mui/material';

import snackBarContext, {
    Notification,
    SnackBarContext
} from 'pages/components/SnackBarProvider/contexts/snack-bar-context';

import useStyles from './styles';

interface Props {
    children: React.ReactNode;
}

const DURATION = 15_000;
const Provider = snackBarContext.Provider;

const SnackBarProvider: React.ForwardRefRenderFunction<SnackBarContext, Props> = ({ children }, ref) => {
    const { classes } = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [notification, setNotification] = useState<Notification | null>(null);

    const openNotification = useCallback((notification: Notification) => {
        setNotification(notification);
        setIsOpen(true);
    }, []);

    const closeNotification = useCallback(() => setIsOpen(false), []);

    const clearNotification = useCallback(() => setNotification(null), []);

    const contextValue = useMemo(() => ({ openNotification, closeNotification }), [
        closeNotification,
        openNotification
    ]);

    useImperativeHandle(ref, () => contextValue, [contextValue]);

    return (
        <Provider value={contextValue}>
            {children}
            <Snackbar
                open={isOpen}
                className={classes.snackBarRoot}
                autoHideDuration={DURATION}
                onClose={closeNotification}
                TransitionProps={{ onExited: clearNotification }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    classes={{
                        root: classes.alertRoot,
                        filledError: classes.alertError,
                        filledSuccess: classes.alertSuccess,
                        icon: classes.alertIcon,
                        message: classes.alertMessage
                    }}
                    severity={notification?.level}
                    action={null}
                    icon={
                        notification?.level === 'error' ? (
                            <Close />
                        ) : notification?.level === 'success' ? (
                            <Check />
                        ) : undefined
                    }
                >
                    {notification?.message}
                </Alert>
            </Snackbar>
        </Provider>
    );
};

export default React.forwardRef(SnackBarProvider);
