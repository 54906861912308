import { action, makeObservable, observable } from 'mobx';

export default class DialogStore {
    @observable loginDialogOpen = false;
    @observable signupDialogOpen = false;
    @observable recoveryDialogOpen = false;

    @observable messageDialogOpen = false;
    @observable messageDialogTitle = '';
    @observable messageDialogBody = '';

    @action
    openLoginDialog = () => {
        this.closeAll();
        this.loginDialogOpen = true;
    };

    @action
    openSignupDialog = () => {
        this.closeAll();
        this.signupDialogOpen = true;
    };

    @action
    openRecoveryDialog = () => {
        this.closeAll();
        this.recoveryDialogOpen = true;
    };

    @action
    openMessageDialog = (title, body) => {
        this.closeAll();
        this.messageDialogBody = body;
        this.messageDialogTitle = title;
        this.messageDialogOpen = true;
    };

    @action
    closeAll = () => {
        this.loginDialogOpen = false;
        this.signupDialogOpen = false;
        this.messageDialogOpen = false;
        this.recoveryDialogOpen = false;
    };

    constructor() {
        makeObservable(this);
    }
}
