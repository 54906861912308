import { action, makeObservable, observable } from 'mobx';

import API from 'app/API';

import License from './models/License';

export default class LicenseStore {
    CHECKOUT_METHODS = {
        EMAIL: 'email',
        PAYPAL: 'paypal'
    };

    @observable licenseList = [];
    @observable isLoading = false;

    @observable checkoutLicense = new License();
    @observable checkoutMethod = '';

    constructor() {
        makeObservable(this);
    }

    @action
    loadLicenses() {
        this.isLoading = true;
        return API.fetch(API.LICENSE_LIST).then((response) => {
            if (process.env.NODE_ENV !== 'production') {
                response = require('../../tests/mocks/licenseList.json');
            }
            this.licenseList = response.data.licenseList.reverse();
            this.isLoading = false;
        });
    }

    @action
    setLicense(checkoutLicense) {
        this.checkoutLicense = checkoutLicense || {};
    }

    @action
    setCheckoutMethod(checkoutMethod) {
        this.checkoutMethod = checkoutMethod;
    }
}
