type Key = string | number;

export default function keyBy<T>(value: T[], iteratee: (item: T) => Key): Record<string, T> {
    if (!Array.isArray(value)) {
        return {};
    }
    return value.reduce((acc, item) => {
        const key = iteratee(item);
        acc[key] = item;
        return acc;
    }, {} as Record<string, T>);
}
