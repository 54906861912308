import { action, makeObservable, observable } from 'mobx';

import Cacheable from 'app/store/Cacheable';
import isOkResponse from 'utils/is-ok-response';

import API from '../API';

export default class LuceneSearchStore extends Cacheable {
    @observable loading = false;
    @observable data = [];

    @action
    doRequest = ({ query = '', fields = [] } = {}) => {
        const request = API.SEARCH;
        const queryKey = JSON.stringify([request.url, query, fields]);

        const payload = { query };

        if (fields.length > 0) {
            payload.fields = fields;
        }

        if (this.hasItemInCache(queryKey)) {
            const { data } = this.getItemFromCache(queryKey);
            this.data = data;
            return;
        }

        this.loading = true;
        return API.fetch(request, payload)
            .then((response) => {
                if (process.env.NODE_ENV !== 'production') {
                    response = require('../../tests/mocks/twitter.json');
                }
                if (isOkResponse(response)) {
                    this.data = response.data ? response.data.search : [];
                    this.setItemToCache(queryKey, { data: this.data });
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }
}
