import { observer } from 'mobx-react';
import React from 'react';

import { SearchContext } from '@elastic/react-search-ui';

import useStores from 'hooks/use-stores';

interface Props {
    children: React.ReactElement | null;
}

const ElasticSearchProvider: React.FC<Props> = ({ children }) => {
    const { searchStore } = useStores();

    if (typeof window === 'undefined') {
        return children;
    }

    return (
        <SearchContext.Provider value={{ driver: searchStore.elasticSearchDriver }}>{children}</SearchContext.Provider>
    );
};

export default observer(ElasticSearchProvider);
