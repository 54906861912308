import { MobXProviderContext } from 'mobx-react';
import { useContext } from 'react';

/**
 * @function
 * @description Returns mobx stores
 * @example const {storeName1, storeName2} = useStores();
 * @returns
 */
export default function useStores() {
    return useContext(MobXProviderContext);
}
