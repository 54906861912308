import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import usePageMeta from 'hooks/use-page-meta';

interface Props extends RouteProps {
    title?: string;
    description?: string;
}

const PageRoute: React.FC<Props> = (props) => {
    const { title, description, children, ...restProps } = props;
    usePageMeta({ title, description });
    return <Route {...restProps}>{children}</Route>;
};

export default React.memo(PageRoute);
