import { action, computed, makeObservable, observable } from 'mobx';

import API from 'app/API';

import UserStore from '../UserStore';

export default class User {
    @observable firstName = '';
    @observable lastName = '';
    @observable username = '';
    @observable email = '';
    @observable telegram = '';
    @observable companyName = '';
    @observable phone = '';
    @observable additionalInfo = '';
    @observable multisession = false;
    @observable marketingAgreement = -1;
    @observable amount = 0;
    @observable totp = false;
    @observable organizationId = '';

    id = '';
    userlevel = 0;

    constructor(serverModel) {
        makeObservable(this);
        serverModel && Object.keys(serverModel).forEach((key) => (this[key] = serverModel[key]));
    }

    @computed get isOrganizationMember() {
        return !!this.organizationId.trim?.();
    }

    @computed get isAdmin() {
        return this.userlevel >= 80;
    }

    @computed get isAuthorized() {
        return !!this.id;
    }

    @computed get isAnonymous() {
        return !this.id;
    }

    @computed get contactInfo() {
        const { firstName, lastName, email, companyName, additionalInfo, phone } = this;
        return { firstName, lastName, email, companyName, additionalInfo, phone };
    }

    @action
    emailCodeConfirm({ code }) {
        return API.fetchWithToken(API.EMAIL_CODE_CONFIRM, { code });
    }

    @action
    changeEmail({ newEmail, password }) {
        return API.fetchWithToken(API.CHANGE_EMAIL, { newEmail, password });
    }

    @action
    saveUserData() {
        const { firstName, lastName, email, telegram, multisession } = this;

        return API.fetchWithToken(API.EDIT_POST, {
            firstName,
            lastName,
            email,
            telegram,
            multisession
        }).then(UserStore.loadUser);
    }

    @action
    totpInitiate(enabled) {
        const { firstName, lastName, email, telegram, multisession } = this;

        return API.fetchWithToken(API.EDIT_POST, {
            firstName,
            lastName,
            email,
            telegram,
            multisession,
            totp: enabled
        });
    }

    @action
    totpEnable(totpCode) {
        return API.fetchWithToken(API.USER_TOTP_ENABLE, { totpCode });
    }

    @computed get fullName() {
        return [this.firstName, this.lastName].filter(Boolean).join(' ') || this.username || '';
    }
}
