import { action, makeObservable, observable } from 'mobx';

import API from 'app/API';
import Cacheable from 'app/store/Cacheable';
import isOkResponse from 'utils/is-ok-response';

export default class CveLineChartStore extends Cacheable {
    @observable data = {};
    @observable isLoading = false;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }

    consume(cachedEntry) {
        if (cachedEntry.data) {
            this.data = cachedEntry.data;
        }
    }

    @action
    loadData = ({ termName, termValue }) => {
        const request = API.AGGREGATION;
        const queryKey = JSON.stringify([request.url, termName, termValue]);

        if (this.hasItemInCache(queryKey)) {
            return this.consume(this.getItemFromCache(queryKey));
        }

        this.isLoading = true;
        return API.fetch(request, { termName, termValue })
            .then((response) => {
                if (isOkResponse(response)) {
                    this.data = response.data.bulletingAggregation;
                    this.setItemToCache(queryKey, { data: this.data });
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    };
}
