import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Box, Paper, Snackbar } from '@mui/material';

import useStores from 'app/main/hooks/use-stores';

import Password from '../user/Password.jsx';

const PasswordRecovery = () => {
    const [snackMessage, setSnackMessage] = useState('');
    const history = useHistory();
    const { userStore } = useStores();
    const { editingSubscription } = userStore;

    useEffect(() => {
        if (editingSubscription.hasPendingState()) {
            userStore.loadUser().then(() => {
                editingSubscription.completePendingState();
            });
        }
    }, [userStore, editingSubscription]);

    const handlePasswordChange = () => {
        setSnackMessage('Password has been Successfully changed!');

        setTimeout(() => history.push({ pathname: '/search' }), 700);
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="center" style={{ height: '100%' }}>
            <Paper style={{ padding: 40 }}>
                <Password onMessage={setSnackMessage} onPasswordChange={handlePasswordChange} />
            </Paper>

            <Snackbar open={!!snackMessage} message={snackMessage} autoHideDuration={4000} />
        </Box>
    );
};

export default observer(PasswordRecovery);
