import { action, computed, makeObservable, observable } from 'mobx';
import xss from 'xss';

import API from 'app/API';
import Cacheable from 'app/store/Cacheable';
import { renderMd } from 'pages/Search/components/SearchItem/utils';
import isOkResponse from 'utils/is-ok-response';
import Utils from 'utils/Utils';

// TODO: add explicit typings

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BulletinData = Record<string, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BulletinReferences = Record<string, any>;

export default class BulletinStore extends Cacheable {
    @observable data: BulletinData = {};
    @observable references: BulletinReferences = {};
    @observable isLoading = false;

    controller: AbortController | null = null;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }

    consume(cachedEntry) {
        if (cachedEntry.data) {
            this.data = cachedEntry.data;
        }
        if (cachedEntry.references) {
            this.references = cachedEntry.references;
        }
    }

    flattenReferences(references: BulletinReferences) {
        const flatRefs = {};
        Object.entries(references).forEach(([bulletinFamily, bulletins]) => {
            flatRefs[bulletinFamily] = bulletins.map((bulletin) => {
                return {
                    id: bulletin.id,
                    type: bulletin.type,
                    title: bulletin.title,
                    published: bulletin.published,
                    bulletinFamily
                };
            });
        });
        return flatRefs;
    }

    @action loadItem = async ({ id }: { id: string }) => {
        const request = API.SEARCH_ID;
        const queryKey = JSON.stringify([request.url, id]);

        if (this.hasItemInCache(queryKey)) {
            return this.consume(this.getItemFromCache(queryKey));
        }

        this.controller = new AbortController();
        this.isLoading = true;
        try {
            const response = await API.fetch(
                API.SEARCH_ID,
                { id, references: 'True' },
                null,
                null,
                null,
                this.controller.signal
            );
            if (isOkResponse(response)) {
                this.data = response.data?.documents?.[id] || {};
                this.references = this.flattenReferences(response.data?.references?.[id] || {});
                this.setItemToCache(queryKey, { data: this.data, references: this.references });
            }
        } finally {
            this.isLoading = false;
        }
    };

    @computed
    get shortTitle() {
        if (this.data.id) {
            const { id, title, bulletinFamily } = this.data;
            const brief = title || id;

            const postfix =
                bulletinFamily === 'exploit'
                    ? ' - exploit database | Vulners.com'
                    : ' - vulnerability database | Vulners.com';
            const shortTitle = Utils.trunc(
                100 - postfix.length,
                xss(`${brief}`)
                    .replace(/[\r\n\\]/g, '')
                    .trim()
            );
            return shortTitle + postfix;
        } else {
            return '';
        }
    }

    @computed
    get shortDescription() {
        if (this.data.description) {
            return Utils.trunc(
                250,
                renderMd(this.data.description)
                    .replace(/[\r\n\\]/g, '')
                    .trim()
            );
        }
        return '';
    }
}
