interface ConstructorOptions {
    name: string;
    debug?: boolean;
}

export default class Loggable {
    protected readonly debug: boolean;
    protected readonly name: string;

    constructor(options: ConstructorOptions) {
        this.name = options.name;
        this.debug = !!options.debug;
    }

    protected log(topic: string, ...messages: unknown[]) {
        if (this.debug) {
            console.info(`[${this.name} - ${topic}]: `, ...messages.map((message) => JSON.stringify(message, null, 2)));
        }
    }
}
