import { action, makeObservable, observable } from 'mobx';

import API from 'app/API';
import Cacheable from 'app/store/Cacheable';
import isOkResponse from 'utils/is-ok-response';

export default class NASLStore extends Cacheable {
    @observable loading = false;
    @observable naslTypes = [];

    @action
    loadTypes = () => {
        if ((this.naslTypes && this.naslTypes.length > 0) || this.loading) {
            return;
        }

        const request = API.NASL_TYPES;
        const queryKey = request.url;

        if (this.hasItemInCache(queryKey)) {
            const { naslTypes } = this.getItemFromCache(queryKey);
            this.naslTypes = naslTypes;
            return;
        }

        this.loading = true;
        return API.fetch(request)
            .then((response) => {
                if (isOkResponse(response)) {
                    this.naslTypes = response.data ? response.data.types || [] : [];
                    this.setItemToCache(queryKey, { naslTypes: this.naslTypes });
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }
}
