interface Options {
    recursive?: boolean;
}

export default function isEmpty(value: unknown, options: Options = {}): boolean {
    if (typeof value === 'string') {
        return value.length === 0;
    }
    if (typeof value === 'undefined' || value === null) {
        return true;
    }
    if (Array.isArray(value)) {
        if (options.recursive) {
            return value.length === 0 || value.every((item) => isEmpty(item));
        } else {
            return value.length === 0;
        }
    }
    if (value instanceof Set || value instanceof Map) {
        return value.size === 0;
    }
    if (typeof value === 'object') {
        if (options.recursive) {
            const values = Object.values(value);
            return values.length === 0 || values.every((value) => isEmpty(value));
        } else {
            return Object.keys(value).length === 0;
        }
    }
    return false;
}
