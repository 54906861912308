import API from 'app/API';
import isBrowser from 'utils/is-browser';
import Utils from 'utils/Utils';
import waitScriptReady from 'utils/wait-for-script-ready';

export default class Notifications {
    static SERVICE_WORKER_PATH = '/static/js/sw.js';
    static APPLICATION_SERVER_PUBLICK_KEY =
        'AAAAgRz26PE:APA91bGTQkMM9Y-OK9gNrf5mfZBFOkbWNH0JbGd-SCANJon4zQSNVp1_z_xE027yVahulP5X_i4-MBGhcCp2FrZDEI2EQJ41I5R819J8Csc4p5dFSOHMZdYYCckcQAqOr4ynuezfO1jG';

    register() {
        this.messaging
            .requestPermission()
            .then(() => this.getToken())
            .catch(() => null);
    }

    getToken() {
        this.messaging
            .getToken()
            .then((authToken) => API.fetchWithToken(API.NOTIFICATION_REGISTER, { authToken }))
            .catch(() => null);
    }

    onTokenRefresh() {
        this.messaging
            .getToken()
            .then((authToken) => API.fetchWithToken(API.NOTIFICATION_REGISTER, { authToken }))
            .catch(() => null);
    }

    onMessage(response) {
        const data = response.data;
        const notificationTitle = data.title;
        const notificationOptions = {
            body: data.description,
            icon: Utils.getImage(data.type)
        };

        const notification = new Notification(notificationTitle, notificationOptions);

        notification.onclick = () => {
            window.open(`https://vulners.com/${data.type}/${data.id}`);
        };

        return notification;
    }

    start() {
        if (!isBrowser()) {
            return;
        }

        return Promise.all([
            waitScriptReady('firebase-script', { alwaysResolve: true }),
            waitScriptReady('firebase-app-script', { alwaysResolve: true }),
            waitScriptReady('firebase-messaging-script', { alwaysResolve: true })
        ])
            .then(() => {
                if (window.firebase && !window.firebase?.apps?.length) {
                    window.firebase.initializeApp({
                        apiKey: 'AIzaSyDZYvPOmPeJ_QpuFvtxlzRpX1GezW1lyeI',
                        authDomain: 'vulners-6512e.firebaseapp.com',
                        databaseURL: 'https://vulners-6512e.firebaseio.com',
                        storageBucket: 'vulners-6512e.appspot.com',
                        messagingSenderId: '554536724721'
                    });

                    this.messaging = window.firebase.messaging();
                    this.messaging.onTokenRefresh(this.onTokenRefresh);
                    this.messaging.onMessage(this.onMessage);

                    if ('serviceWorker' in navigator || 'PushManager' in window) {
                        return navigator.serviceWorker.register(Notifications.SERVICE_WORKER_PATH);
                    } else {
                        console.warn('Push messaging is not supported');
                    }
                }
            })
            .then((swReg) => {
                if (swReg) {
                    this.messaging.useServiceWorker(swReg);
                    this.register();
                }
            })
            .catch((error) => console.error('Service Worker Error', error));
    }
}
