import { observer } from 'mobx-react';
import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';

import useStores from 'hooks/use-stores';
import PageRoute from 'main/components/PageRoute';

interface Props extends RouteProps {
    title?: string;
    description?: string;
    fallbackTo?: string;
}

const ProtectedPageRoute: React.FC<Props> = ({ fallbackTo = '/login', ...restProps }) => {
    const { userStore } = useStores();

    if (userStore.userLoaded) {
        return userStore.isAuthorized ? <PageRoute {...restProps} /> : <Redirect to={fallbackTo} />;
    } else {
        return null;
    }
};

export default observer(ProtectedPageRoute);
