import Fingerprint2 from 'fingerprintjs2';
import { action, makeObservable, observable } from 'mobx';

import API from 'app/API';

let CAPTCHA_KEY = '6LebRZQUAAAAAMrdwqoFrTsVzcM1VL6c6aRhfN05';
const PRECISION = 0.7;

export default class AntiBotStore {
    @observable antiBotDialogOpen = false;

    constructor() {
        makeObservable(this);
    }

    @action openDialog() {
        this.antiBotDialogOpen = true;
    }

    @action closeDialog() {
        this.antiBotDialogOpen = false;
    }

    @action collectData() {
        if (!CAPTCHA_KEY) {
            return this._getCaptchaKeys();
        }
        try {
            this._collectCaptcha();
        } catch (e) {
            setTimeout(this._collectCaptcha, 1000);
        }
    }

    @action randomCollectData() {
        if (parseFloat(Math.random().toPrecision(1)) > PRECISION) {
            this.collectData();
        }
    }

    /**
     * @private
     */
    _getCaptchaKeys() {
        API.fetch(API.CAPTCHA).then((data) => {
            CAPTCHA_KEY = data.data.publickey_v3;
            this.collectData();
        });
    }

    /**
     * @private
     */
    _collectCaptcha() {
        const me = this;
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(CAPTCHA_KEY, { action: window.location.pathname }).then((response) => {
                API.fetch(API.CAPTCHA_ANTIBOT, { response });
                me._collectFingerprint();
            });
        });
    }

    /**
     * @private
     */
    _collectFingerprint() {
        Fingerprint2.getV18({}, (result, components) => {
            API.fetch(API.FINGERPRINT, {
                result: result,
                components: components
            });
        });
    }
}
