import React, { createContext } from 'react';

export interface Notification {
    level: 'error' | 'warning' | 'info' | 'success';
    message: React.ReactNode;
}

export interface SnackBarContext {
    openNotification: (params: Notification) => void;
    closeNotification: () => void;
}

const defaultValue: SnackBarContext = {
    openNotification: () => undefined,
    closeNotification: () => undefined
};

const snackBarContext = createContext(defaultValue);
export default snackBarContext;
