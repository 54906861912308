import { APIConnector, AutocompleteResponseState, RequestState, ResponseState } from '@elastic/search-ui';

interface ConstructorOptions {
    onSearchImplementation: (state: RequestState) => Promise<ResponseState>;
}

export class ElasticApiConnector implements APIConnector {
    onSearchImplementation: (state: RequestState) => Promise<ResponseState>;

    constructor({ onSearchImplementation }: ConstructorOptions) {
        this.onSearchImplementation = onSearchImplementation;
    }

    async onSearch(state: RequestState): Promise<ResponseState> {
        return this.onSearchImplementation(state);
    }

    async onAutocomplete(): Promise<AutocompleteResponseState> {
        throw new Error('Not implemented');
    }

    onAutocompleteResultClick(): void {
        throw new Error('Not implemented');
    }

    onResultClick(): void {
        throw new Error('Not implemented');
    }
}
