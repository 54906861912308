import PropTypes from 'prop-types';
import React from 'react';

import API from 'app/API';
import waitScriptReady from 'utils/wait-for-script-ready';

export default class Captcha extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        callback: PropTypes.func,
        invisible: PropTypes.bool,
        controlled: PropTypes.bool,
        style: PropTypes.object
    };

    state = {
        captchaId: ''
    };

    componentDidMount() {
        if (this.props.controlled) {
            return;
        }
        this.recoverCaptchaId();
    }

    recoverCaptchaId = () => {
        waitScriptReady('google-recaptcha-script', { alwaysResolve: true })
            .then(() => API.fetch(API.CAPTCHA))
            .then((data) => {
                const publickey = data?.data?.publickey;
                if (!publickey) {
                    console.error('[Captcha] Public key is not defined', data);
                    return;
                }
                try {
                    this.initCaptcha(publickey);
                } catch (_error) {
                    setTimeout(() => this.initCaptcha(publickey), 1000);
                }
            });
    };

    initCaptcha = (sitekey) =>
        this.setState({
            captchaId: window.grecaptcha.render(document.getElementById(this.props.id), {
                sitekey,
                callback: this.props.callback
            })
        });

    getValue = () => window.grecaptcha.getResponse(this.state.captchaId);

    resetValue = () => window.grecaptcha.reset();

    render() {
        return (
            <div
                id={this.props.id}
                style={this.props.style}
                data-size={this.props.invisible ? 'invisible' : ''}
                className="g-recaptcha"
            />
        );
    }
}
