import { action, makeObservable, observable } from 'mobx';

import API from 'app/API';
import isOkResponse from 'utils/is-ok-response';

export default class Webhook {
    @observable id;
    @observable query = '';
    @observable apiKey = '';
    @observable active = false;

    @observable errorMessage = '';
    @observable successMessage = '';

    @observable loading = false;

    constructor(userStore, serverModel) {
        makeObservable(this);
        this.userStore = userStore;
        if (serverModel) {
            Object.keys(serverModel).forEach((key) => (this[key] = serverModel[key]));
        }
    }

    @action
    create = async () => {
        if (this.loading) {
            return;
        }

        const { query, apiKey } = this;

        if (!query.trim()) {
            this.errorMessage = 'Searching Query is not specified';
            return;
        }
        if (!apiKey.trim()) {
            this.errorMessage = 'API key is not specified';
            return;
        }

        this.clearMessages();
        this.loading = true;

        try {
            const response = await API.fetchWithToken(API.SUBSCRIPTIONS_WEBHOOK_ADD, { query, apiKey });
            if (isOkResponse(response)) {
                this.setSuccessMessage('Successfully created!');
                this.userStore.loadWebhookSubscriptions();
                this.id = response.data.subscriptionid;
                return this.id;
            } else {
                const errorMessage = response.data?.error || 'Something went wrong, please, contact support';
                throw new Error(errorMessage);
            }
        } catch (error) {
            this.setErrorMessage(error.message);
        } finally {
            this.loading = false;
        }
    };

    @action
    activate = () => {
        if (this.loading) {
            return;
        }

        this.clearMessages();
        this.loading = true;

        const { id, query, active } = this;

        API.fetchWithToken(API.SUBSCRIPTIONS_WEBHOOK_EDIT, {
            subscriptionid: id,
            query,
            active: String(!active)
        }).then(({ data }) => {
            this.loading = false;
            switch (data.errorCode) {
                case 147:
                case 150:
                    return this.setErrorMessage(data.error);
                default:
                    this.setSuccessMessage(`Successfully ${active ? 'deactivated' : 'activated'}!`);
                    this.setActive(!active);
                    this.userStore.loadWebhookSubscriptions();
                    return;
            }
        });
    };

    @action
    delete = () => {
        if (this.loading) {
            return;
        }

        this.clearMessages();
        this.loading = true;

        API.fetchWithToken(API.SUBSCRIPTIONS_WEBHOOK_REMOVE, { subscriptionid: this.id }).then(({ data, result }) => {
            this.loading = false;

            if (result === 'OK') {
                this.setSuccessMessage('Successfully removed!');
                this.userStore.loadWebhookSubscriptions();
            } else {
                this.setErrorMessage(data.error);
            }
        });
    };

    @action
    clearMessages = () => {
        this.errorMessage = '';
        this.successMessage = '';
    };

    @action
    setErrorMessage = (message) => (this.errorMessage = message);

    @action
    setSuccessMessage = (message) => (this.successMessage = message);

    @action
    setQuery = (query) => (this.query = query);

    @action
    setApiKey = (apiKey) => (this.apiKey = apiKey);

    @action
    setActive = (active) => (this.active = active);
}
