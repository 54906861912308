import React, { useState } from 'react';

import ActionLockOutline from '@mui/icons-material/LockOutlined';
import { Button, Card, CardActions, CardHeader, LinearProgress, TextField, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';

import API from 'app/API';

export default (props) => {
    const [loading, setLoading] = useState(false);
    const [passwordOld, setPasswordOld] = useState('');
    const [passwordNew, setPasswordNew] = useState('');
    const [passwordNewRepeat, setPasswordNewRepeat] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordRepeatError, setPasswordRepeatError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleChangePassword = () => {
        setLoading(true);
        API.fetchWithToken(API.CHANGE_PASSWORD, {
            password: passwordNewRepeat,
            oldpassword: props.needOldPassword ? passwordOld : ' '
        }).then((response) => {
            setLoading(false);
            if (response.result === 'OK') {
                setSuccess(true);
                props.onPasswordChange && props.onPasswordChange(response);
            }
            props.onMessage && props.onMessage(response.data.error || 'Successfully Saved!');
            setPasswordOld('');
            setPasswordNew('');
            setPasswordNewRepeat('');
        });
    };

    const checkPassRepeat = (e) => {
        setPasswordNewRepeat(e.target.value);
        setPasswordRepeatError(e.target.value === passwordNew ? '' : 'Passwords are different');
    };

    /**
     * @Check pass strength
     */
    const checkPassStrength = (e) => {
        const passwordNew = e.target.value;
        setPasswordNew(passwordNew);

        API.fetch(API.CHECK_PASSWORD, {
            password: passwordNew
        }).then(({ data }) => {
            const errors = Object.keys(data.improvements).map((k) => data.improvements[k]);
            setPasswordError(data.strenght > 0.5 ? '' : errors[0]);
        });
    };

    return (
        <Card>
            <CardHeader title="Change Password" avatar={<ActionLockOutline />} />
            <CardContent>
                <div>
                    {props.needOldPassword && (
                        <TextField
                            name="old-password"
                            type="password"
                            label="Old Password"
                            disabled={loading}
                            onChange={(e) => setPasswordOld(e.target.value)}
                        />
                    )}
                    <br />
                    <TextField
                        name="new-password"
                        type="password"
                        label="Password"
                        disabled={loading}
                        error={!!passwordError}
                        helperText={passwordError}
                        onBlur={checkPassStrength}
                        onChange={(e) => passwordNew && passwordNewRepeat && checkPassRepeat(e)}
                    />
                    <br />
                    <TextField
                        name="repeat-password"
                        label="Repeat Password"
                        type="password"
                        disabled={loading}
                        error={!!passwordRepeatError}
                        helperText={passwordRepeatError}
                        onChange={checkPassRepeat}
                    />
                    <br />
                    {loading && <LinearProgress mode="indeterminate" />}

                    {props.needOldPassword && success && (
                        <Typography variant="body2" color="primary">
                            Passsword changed successfully!
                        </Typography>
                    )}
                </div>
            </CardContent>
            <CardActions>
                <Button color="primary" onClick={handleChangePassword}>
                    Change Password
                </Button>
            </CardActions>
        </Card>
    );
};
