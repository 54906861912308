import { action, makeObservable, observable } from 'mobx';

import API from 'app/API';

export default class ApiKey {
    @observable id = '';
    @observable name = '';
    @observable scope = [];
    @observable licenseId = '';
    @observable licenseType = '';
    @observable securityLimits = {
        ipaddressList: []
    };

    @observable loading = false;
    @observable error = '';
    userStore = null;

    constructor(userStore, serverModel) {
        makeObservable(this);
        this.userStore = userStore;
        serverModel && Object.keys(serverModel).forEach((key) => (this[key] = serverModel[key]));
    }

    @action
    setName = (name) => (this.name = name);

    @action
    setScope = (scope) => (this.scope = scope);

    @action
    setLicense = (licenseId) => (this.licenseId = licenseId);

    @action
    setIPAddressList = (ipaddressList) => (this.securityLimits.ipaddressList = ipaddressList);

    @action
    setError = (error) => (this.error = error);

    @action
    save = () => {
        this.loading = true;

        return API.fetchWithToken(this.id ? API.KEY_MODIFY : API.KEY_CREATE, {
            keyID: this.id,
            name: this.name,
            scope: this.scope,
            licenseId: this.licenseId,
            licenseType: this.licenseType,
            ipaddressList: this.securityLimits.ipaddressList
        }).then((response) => {
            this.loading = false;
            if (response.result === 'error') {
                this.error = response.data.error;
            } else {
                this.userStore.loadApiKeys();
                this.userStore.setEditingApiKey();
                return response.data.apiKey;
            }
        });
    };

    @action
    delete = () => {
        this.loading = true;
        return API.fetchWithToken(API.KEY_DELETE, { keyID: this.id }).then((response) => {
            this.loading = false;
            if (response.result === 'error') {
                this.error = response.data.error;
            } else {
                this.userStore.loadApiKeys();
            }
        });
    };
}
