import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    snackBarRoot: {
        ['&&']: {
            bottom: '0'
        }
    },
    alertRoot: {
        padding: '24px 40px 24px 32px',
        alignItems: 'center',

        borderRadius: '16px 16px 0px 0px'
    },
    alertMessage: {
        padding: 0,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '17px'
    },
    alertIcon: {
        margin: '0px 12px 0px 0px',
        padding: 0,
        ['& > svg']: {
            width: 16,
            height: 16
        }
    },
    alertError: {
        color: '#fff',
        backgroundColor: '#ff3306',
        boxShadow: '0px 20px 50px 0px rgba(255, 51, 6, 0.35), 0px 4px 10px 0px rgba(255, 51, 6, 0.15)'
    },
    alertSuccess: {
        color: '#fff',
        backgroundColor: '#4caf52',
        boxShadow: '0px 20px 50px 0px rgba(76, 175, 82, 0.35), 0px 4px 10px 0px rgba(76, 175, 82, 0.15)'
    }
});
