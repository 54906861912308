import { action, makeObservable, observable } from 'mobx';

export default class ImagesStore {
    @observable data = null;
    @observable query = '';
    @observable listLength = 20;

    constructor() {
        makeObservable(this);
    }

    @action
    setQuery(query) {
        this.query = query;
    }

    @action
    doRequest(_query, _skip) {}
}
