import { action, makeObservable, observable } from 'mobx';

import { hashKey } from '@tanstack/react-query';

import API from 'app/API';
import Cacheable from 'app/store/Cacheable';
import isOkResponse from 'utils/is-ok-response';

export default class CveItemsStore extends Cacheable {
    static PAGE_SIZE = 50;

    static ORDER_2_LIMIT = {
        ['most-viewed']: 5000
    };

    static ORDER_2_FIELD_MAP = {
        ['date']: 'published',
        ['most-viewed']: 'viewCount'
    };

    @observable list = [];
    @observable totalCount = 0;

    @observable page = 1;
    @observable vendorName = '';
    @observable productName = '';
    @observable typeName = '';
    @observable order = '';

    @observable score = null;
    @observable year = null;
    @observable month = null;

    @observable isLoading = false;

    /** @type {AbortController | null} */
    controller = null;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }

    consume(cachedEntry) {
        if (cachedEntry.list) {
            this.list = cachedEntry.list;
        }
        if (cachedEntry.totalCount) {
            this.totalCount = cachedEntry.totalCount;
        }
        if (cachedEntry.params) {
            Object.assign(this, cachedEntry.params);
        }
    }

    @action
    loadItems = (route) => {
        if (!this.vendorName && !this.productName && !this.typeName) {
            return;
        }

        if (this.isLoading) {
            if (this.controller) {
                this.controller.abort();
                this.controller = null;
                this.isLoading = false;
            } else {
                return;
            }
        }

        const request = route({
            productName: this.productName,
            vendorName: this.vendorName,
            typeName: this.typeName,
            score: this.score,
            year: this.year,
            month: this.month,
            order: CveItemsStore.ORDER_2_FIELD_MAP[this.order],
            skip: Math.max(this.page - 1, 0) * CveItemsStore.PAGE_SIZE,
            size: CveItemsStore.PAGE_SIZE
        });
        const queryKey = request.method === 'post' ? hashKey([request.body]) : request.url;

        if (this.hasItemInCache(queryKey)) {
            return this.consume(this.getItemFromCache(queryKey));
        }

        this.controller = new AbortController();
        this.isLoading = true;
        return API.fetch(request, request.body ?? null, null, null, null, this.controller.signal)
            .then((response) => {
                if (isOkResponse(response)) {
                    this.list = response.data.search.map(({ flatDescription, _source: source, inner_hits }) => ({
                        item: { flatDescription, ...source },
                        hits: inner_hits?.most_recent?.hits
                    }));
                    this.totalCount = response.data.total;
                    this.setItemToCache(queryKey, { list: this.list, totalCount: this.totalCount });
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    };

    @action
    loadProductCveItems = () => {
        return this.loadItems(API.CVE_CATALOGUE_PRODUCT_CVE);
    };

    @action
    loadVendorCveItems = () => {
        return this.loadItems(API.CVE_CATALOGUE_VENDOR_CVE);
    };

    @action
    loadTypeCveItems = () => {
        return this.loadItems(API.CVE_BY_TYPE_CATALOGUE);
    };

    @action
    setParams = ({
        page = this.page,
        vendorName = this.vendorName,
        productName = this.productName,
        typeName = this.typeName,
        order = this.order,
        score = this.score,
        year = this.year,
        month = this.month
    }) => {
        this.page = page;
        this.vendorName = vendorName;
        this.productName = productName;
        this.typeName = typeName;
        this.order = order;
        this.score = score;
        this.year = year;
        this.month = month;
        this.setItemToCache('params', {
            params: { page, vendorName, productName, typeName, order, score, year, month }
        });
        return this;
    };
}
