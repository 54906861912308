import fetch from 'cross-fetch';
import createHttpError from 'http-errors';
import qs from 'qs';

import isObjectLike from 'utils/is-object-like';

import { BaseRoute, QueryKey, Route } from './types';

const logger = console;

export const isJson = (response: Response) =>
    response.headers.get('content-type')?.includes('application/json') &&
    response.headers.get('content-length') !== '0';

export const buildErrorFromResponse = async (response: Response) => {
    const fromJson = await response
        .json()
        .then((json) => createHttpError(response.status, json.message ?? JSON.stringify(json)))
        .catch(() => null);

    if (fromJson) {
        return fromJson;
    }

    const fromText = await response
        .text()
        .then((text) => createHttpError(response.status, text))
        .catch(() => null);

    if (fromText) {
        return fromText;
    }

    return createHttpError(response.status, response.statusText || 'Network response was not ok');
};

export const makeApiUrl = (route: Route | string) => {
    if (typeof route === 'string') {
        return route;
    } else {
        return (
            '/' +
            [route.prefix, route.version, route.path]
                .join('/')
                .concat(qs.stringify(route.search, { addQueryPrefix: true, indices: false }))
        );
    }
};

let jwt: Promise<string> | null = null;

export const getJwtToken = async (options?: { signal?: AbortSignal }) => {
    jwt ||= fetch('/api/v3/JwtIssuer/getsessiontoken/', { method: 'post', credentials: 'same-origin', ...options })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw buildErrorFromResponse(response);
            }
        })
        .then((json) => {
            if (
                'data' in json &&
                isObjectLike(json.data) &&
                'token' in json.data &&
                typeof json.data.token === 'string'
            ) {
                return json.data.token;
            } else {
                throw json;
            }
        })
        .catch((error) => {
            logger.error(error.message, {
                error,
                source: 'network',
                prefix: 'jwt'
            });

            jwt = null;
        });

    return jwt;
};

export const getCsrfToken = async ({ signal, route }: { signal?: AbortSignal; route: BaseRoute }) => {
    return fetch(makeApiUrl(route), {
        signal,
        headers: { ['Accept']: 'application/json' },
        credentials: 'same-origin'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw buildErrorFromResponse(response);
            }
        })
        .then((json) => {
            if (
                'data' in json &&
                isObjectLike(json.data) &&
                'token' in json.data &&
                typeof json.data.token === 'string'
            ) {
                return json.data.token;
            } else {
                throw json;
            }
        })
        .catch((error) => {
            logger.error(error.message, {
                error,
                source: 'network',
                prefix: 'csrf'
            });
        });
};

export const queryFn = async ({ queryKey, signal }: { queryKey: QueryKey; signal: AbortSignal }) => {
    const [route] = queryKey;

    const headers = new Headers();
    headers.append('Accept', 'application/json');

    if (route.jwt) {
        const jwt = await getJwtToken({ signal });
        headers.append('Authorization', `Bearer ${jwt}`);
    }

    const response = await fetch(
        makeApiUrl(
            route.csrf
                ? {
                      ...route,
                      search: { ...route.search, token: await getCsrfToken({ signal, route: route.csrf }) }
                  }
                : route
        ),
        { signal, headers, credentials: 'same-origin' }
    );

    if (!response.ok) {
        const error = await buildErrorFromResponse(response);

        logger.error(error.message, {
            error,
            additional: queryKey,
            source: 'network',
            prefix: 'queryFn'
        });

        throw error;
    }

    return response.json();
};

export const mutationFn = async (
    route: Route,
    init: Omit<RequestInit, 'body'> & { body: RequestInit['body'] | object }
) => {
    const headers = new Headers(init.headers);

    headers.append('Accept', 'application/json');

    if (route.jwt) {
        const jwt = await getJwtToken();
        headers.append('Authorization', `Bearer ${jwt}`);
    }

    let body = init.body as RequestInit['body'];

    if (isObjectLike(body)) {
        headers.set('content-type', 'application/json');
        if (route.csrf) {
            const csrfToken = await getCsrfToken({ route: route.csrf });
            body = JSON.stringify({ ...body, token: csrfToken });
        } else {
            body = JSON.stringify(init.body);
        }
    }

    const method = init.method || 'post';

    const response = await fetch(makeApiUrl(route), { ...init, method, headers, body, credentials: 'same-origin' });

    if (!response.ok) {
        const error = await buildErrorFromResponse(response);
        logger.error(error.message, {
            error,
            additional: { route, ...init },
            source: 'network',
            prefix: 'mutationFn'
        });

        throw error;
    }

    if (isJson(response)) {
        return response.json();
    }

    return response;
};
