import BlogStore from 'app/store/BlogStore';
import BulletinStore from 'app/store/BulletinStore';
import CveItemsStore from 'app/store/CveItemsStore';
import ProductsCveStore from 'app/store/ProductsCveStore';
import VendorCveStore from 'app/store/VendorCveStore';
import VendorsCveStore from 'app/store/VendorsCveStore';

import AntiBotStore from './AntiBotStore';
import AuditStore from './AuditStore';
import CveLineChartStore from './CveLineChartStore';
import DialogStore from './DialogStore';
import ImagesStore from './ImagesStore';
import LicenseStore from './LicenseStore';
import LuceneSearchStore from './LuceneSearchStore';
import NASLStore from './NASLTypesStore';
import RobotTypesStore from './RobotTypesStore';
import { ScanStore } from './Scan';
import ScanWizardStore from './ScanWizardStore';
import SearchStore from './SearchStore';
import StatsStore from './StatsStore';
import SubscriptionTemplatesStore from './SubscriptionTemplatesStore';
import UserStore from './UserStore';

export function createStores(dehydratedState) {
    const mobxStores = {};
    const bootstrapPromiseExecutors = [];

    mobxStores.userStore = new UserStore();
    mobxStores.naslStore = new NASLStore();
    mobxStores.auditStore = new AuditStore();
    mobxStores.searchStore = new SearchStore();
    mobxStores.dialogStore = new DialogStore();
    mobxStores.scanWizardStore = new ScanWizardStore();
    mobxStores.imagesStore = new ImagesStore();
    mobxStores.sTemplatesStore = new SubscriptionTemplatesStore();
    mobxStores.licenseStore = new LicenseStore();
    mobxStores.antiBotStore = new AntiBotStore();
    mobxStores.scanStore = new ScanStore();
    mobxStores.robotTypesStore = new RobotTypesStore();
    mobxStores.vendorsCveStore = new VendorsCveStore();
    mobxStores.vendorCveStore = new VendorCveStore();
    mobxStores.productsCveStore = new ProductsCveStore();
    mobxStores.cveItemsStore = new CveItemsStore();
    mobxStores.statsStore = new StatsStore(mobxStores.searchStore);
    mobxStores.cveLineChartStore = new CveLineChartStore();
    mobxStores.luceneSearchStore = new LuceneSearchStore();
    mobxStores.bulletinStore = new BulletinStore();
    mobxStores.blogStore = new BlogStore();

    if (dehydratedState) {
        hydrate(mobxStores, dehydratedState);
    }

    for (const key in mobxStores) {
        const store = mobxStores[key];
        if ('bootstrap' in store && typeof store.bootstrap === 'function') {
            bootstrapPromiseExecutors.push(store.bootstrap.bind(store));
        }
    }

    const bootstrap = () => Promise.allSettled(bootstrapPromiseExecutors.map((execute) => execute()));
    return { mobxStores, bootstrap };
}

export function hydrate(stores, dehydratedState) {
    if (typeof dehydratedState === 'string') {
        dehydratedState = JSON.parse(dehydratedState);
    }
    if (typeof dehydratedState === 'object' && dehydratedState !== null) {
        for (const storeName in dehydratedState) {
            const slice = dehydratedState[storeName];
            if (storeName in stores) {
                const store = stores[storeName];
                if ('hydrate' in store && typeof store.hydrate === 'function') {
                    store.hydrate(slice);
                }
            }
        }
    }
}

export function dehydrate(stores) {
    const dehydratedState = {};

    for (const storeName in stores) {
        const store = stores[storeName];
        if ('dehydrate' in store && typeof store.dehydrate === 'function') {
            dehydratedState[storeName] = store.dehydrate();
        }
    }

    return dehydratedState;
}
