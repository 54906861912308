import isBrowser from 'utils/is-browser';

const localStorageStub: Storage = {
    length: 0,
    clear: () => undefined,
    getItem: () => null,
    key: () => null,
    removeItem: () => undefined,
    setItem: () => undefined
};

const isomorphicLocalStorage = isBrowser() ? window.localStorage : localStorageStub;

export default isomorphicLocalStorage;
