class Hubspot {
    /**
     * @param formId {String} Hubspot form Id
     * @param fields {Object} Map of form fields
     */
    static send(formId, fields = null) {
        if (process.env.NODE_ENV !== 'production') {
            console.info('[Hubspot]', formId, fields);
            return;
        }

        try {
            if (!fields || !fields.email) {
                return;
            }

            fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${formId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fields: Object.entries(fields).map(([name, value]) => ({ name, value }))
                })
            });
        } catch (e) {
            console.error('[Hubspot]', e);
        }
    }
}

const HUBSPOT_PORTAL_ID = 19778810;

Hubspot.NETWORK_SCANNER_NEW_PROJECT_CREATED = '8b03ab36-b068-434e-a4c5-b1b2b13b9a8b';
Hubspot.LINUX_SCANNER_NEW_INSTALLATION = '34d7a79c-4207-4aa1-8cdb-1e5348879696';
Hubspot.LINUX_SCANNER_NEW_INSTALLATION_FINISHED = 'ad3dcfda-a6a8-4c31-b040-ad23ee68bef3';
Hubspot.API_KEY_CREATED = 'bd9e71a1-105d-4e95-b077-ba1a6d4fb416';
Hubspot.PRODUCTS_PRODUCT_CLICK = '4b3f3959-fa1a-45c7-80cf-9f5afc4bb505';
Hubspot.DOCS_CLICK = '963f4cbd-eeb5-44d9-b52e-10576c0006ff';
Hubspot.PRICING_CLICK = '90075955-6171-4b0a-bcb3-41575ddd4b03';
Hubspot.CHECKOUT = '5cb4e0e0-b1b0-4bd1-8889-a93b3eebff6e';
Hubspot.CHECKOUT_SUCCESS = 'ed5fb903-83cf-4d30-8e05-cb217c7a7ecf';
Hubspot.SIGNUP = '931630ed-c5bb-4d1a-bc53-b4d5a95f2a42';
Hubspot.SUBSCRIPTION_CREATE = 'a9e70acb-d37c-4d5f-af70-d642b08450a7';
Hubspot.MARKETING_AGREEMENT = 'ec28951a-5409-462a-b198-991ae28ead61';
Hubspot.LANDING_FEED_SIGNUP = '0d7d6987-9b8d-42b0-8d18-edae5127e970';
Hubspot.LANDING_FEED_FORM = 'f3269ec5-b9a7-4aea-87a7-c5b4bf5ec5ef';

Hubspot.LANDING_MAIN_FEED_LEARN_MORE = '89ed5e30-6b77-43f0-9765-901b059b1050';
Hubspot.LANDING_MAIN_ASSESSMENT_LEARN_MORE = '3650a158-f644-4f21-84f4-74435e2d2660';
Hubspot.LANDING_MAIN_SDK_LEARN_MORE = '84769b44-6165-4240-879d-827530311050';
Hubspot.LANDING_ASSESSMENT_SIGN_UP = 'c33bad53-6489-4d68-8793-edcc04073fa6';
Hubspot.LANDING_ASSESSMENT_TRIAL_SIGN_UP = '25c8011e-32aa-4d14-95b6-1ed707af2430';
Hubspot.LANDING_ASSESSMENT_FORM_CTA = '98ed4988-bf50-4295-bc37-65ec3c5cdecb';
Hubspot.LANDING_FEED_SIGN_UP = '46814a02-0c13-4e6d-bea7-8788b9e21d65';
Hubspot.LANDING_FEED_STEPS_SIGN_UP = '183b0f07-29df-4152-b935-8255f852d958';
Hubspot.LANDING_FEED_PLUGIN_BURP = '924adb0c-d127-4552-9071-9835924c0c68';
Hubspot.LANDING_FEED_PLUGIN_NMAP = '6e9e8083-557c-4506-a5f4-42c7df2ea0ad';
Hubspot.LANDING_FEED_PLUGIN_SPLUNK = '924adb0c-d127-4552-9071-9835924c0c68';
Hubspot.LANDING_FEED_PLUGIN_ALL = 'a2448424-c5ad-49d4-8c2c-bf2f3071cafc';
Hubspot.LANDING_FEED_FORM_CTA = 'f3269ec5-b9a7-4aea-87a7-c5b4bf5ec5ef';
Hubspot.LANDING_SDK_SIGN_UP = 'ce5c51e1-f91a-411e-a514-9597e0845648';
Hubspot.LANDING_SDK_LEARN_MORE = 'cf4edb3a-91ea-4b93-9dd7-a5e6452c6952';
Hubspot.LANDING_SDK_PARTNER = '2dec12da-44b7-4665-88d1-09f5b0a1376e';
Hubspot.LANDING_SDK_FORM_CTA = '86a146d2-0863-465c-b00e-f9bbec23d709';

Hubspot.REQUEST_CHECKOUT_INVOICE_FORM = '47a3aba3-38e4-4a06-af9c-34731e87fcec';

export default Hubspot;
